import { Avatar, ListItemAvatar } from '@material-ui/core';
import styled from 'styled-components';

import { BodyRegular, lightTheme } from '../../styles';
import { ItemListProps } from '../../types';
import { BootstrapTooltip } from './BootstrapTooltip';
import { Column, Row } from './framing';

const ListItemRow = styled(Row)<{ shouldHover?: boolean; disabled?: boolean }>`
    width: 100%;
    align-items: center;
    flex: unset;
    flex-shrink: 0;
    cursor: ${(props) => (props.disabled ? 'not-allowed' : props.shouldHover ? 'pointer' : 'auto')};
    .list-item-text {
        ${(props) => (props.disabled ? `color: #888888` : '')}
    }
    &:hover {
        border-color: ${(props) => (props.shouldHover ? '#b8b8c5' : 'none')};
        background-color: ${(props) => (props.shouldHover ? lightTheme.neutrals.grayLight : 'none')};
    }
`;

const ListItemText = styled(BodyRegular)`
    padding-top: 10px;
    padding-bottom: 10px;
    width: 100%;
`;

export const ItemList = <Code extends string | number | null, Label extends string | JSX.Element = string>({
    values,
    handleSelection,
    textStyle,
    shouldListItemHover,
    ...props
}: ItemListProps<Code, Label>) => {
    return (
        <Column style={{ width: '100%' }}>
            {values.map(
                (
                    {
                        label,
                        postContent,
                        hidePreContentAvatar,
                        code,
                        preContent,
                        tooltip,
                        tooltipStyle,
                        arrowStyle,
                        disabled,
                        isTooltipInteractive,
                        tooltipPlacement,
                    },
                    i
                ) => (
                    <BootstrapTooltip
                        tooltipStyle={tooltipStyle}
                        title={tooltip ?? ''}
                        arrowStyle={arrowStyle}
                        placement={tooltipPlacement}
                        interactive={isTooltipInteractive}
                        key={code ?? i}
                    >
                        <ListItemRow
                            key={code}
                            shouldHover={shouldListItemHover}
                            disabled={disabled}
                            onClick={() =>
                                handleSelection?.(code, { label, postContent, code, preContent, tooltip, disabled })
                            }
                            {...props}
                        >
                            {!!preContent ? (
                                hidePreContentAvatar ? (
                                    preContent
                                ) : (
                                    <ListItemAvatar>
                                        <Avatar>{preContent}</Avatar>
                                    </ListItemAvatar>
                                )
                            ) : null}
                            <ListItemText className="list-item-text" style={{ ...textStyle }}>
                                {label}
                            </ListItemText>
                            {postContent}
                        </ListItemRow>
                    </BootstrapTooltip>
                )
            )}
        </Column>
    );
};
