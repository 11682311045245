export type SayFunction = (
    toSay: string,
    downloadOnly?: boolean,
    options?: { cacheRemotely: boolean; aboutToPlay?: () => void }
) => Promise<void>;

export enum AgentCommandType {
    Voice = 'Voice',
    ChatMessage = 'Chat Message',
}

export type SendFunction = (event: any, payload?: any) => void;
