import React from 'react';
import styled from 'styled-components';

import {
    FEATURE_INTENT_DESCRIPTION,
    MEETING_TYPE_FEATURE_INTENT_MAP,
    NestedSummaryFeatureIntentKeys,
    ScribeFeatureIntentLabelMap,
    ScribeMeetingTypeLabelMap,
} from '@spinach-shared/constants';
import { FeatureIntent, ScribeMeetingType } from '@spinach-shared/types';

import { BodyRegularOnboard } from '../../../../styles';
import { Column, Row, Spacing } from '../../../common';

const NestedSummaryTemplateBody = styled(BodyRegularOnboard)`
    color: black;
`;

const NestedSummaryTemplateHeader = styled(BodyRegularOnboard)`
    color: black;
    font-weight: 700;
`;

const SummaryTemplateHeader = styled(NestedSummaryTemplateHeader)`
    font-size: 22px;
`;

const SummaryTemplateLabelHeader = styled(NestedSummaryTemplateHeader)`
    font-size: 18px;
`;

const SummaryTemplateDescription = styled(BodyRegularOnboard)`
    color: black;
`;

export const NestedSummaryExampleTemplate = ({ label, description }: { label: string; description: string }) => {
    return (
        <ul
            style={{
                paddingLeft: '23px',
                color: 'black',
                display: 'flex',
                justifyItems: 'center',
                flexDirection: 'column',
            }}
        >
            <li>
                <NestedSummaryTemplateHeader>{label}</NestedSummaryTemplateHeader>
            </li>
            <ul style={{ color: 'black' }}>
                <li>
                    <NestedSummaryTemplateBody style={{ color: 'black' }}>{description}</NestedSummaryTemplateBody>
                </li>
            </ul>
        </ul>
    );
};

export const PlanningSummaryStructure = () => {
    return (
        <Column>
            <Row>
                <SummaryTemplateHeader>Planning Summary Structure</SummaryTemplateHeader>
            </Row>
            <Spacing factor={1 / 3} />
            <Column>
                <Row>
                    <SummaryTemplateLabelHeader>Sprint Goal</SummaryTemplateLabelHeader>
                </Row>

                <Row>
                    <SummaryTemplateDescription>Objectives for the sprint</SummaryTemplateDescription>
                </Row>
            </Column>
            <Spacing factor={1 / 3} />
            <Column>
                <Row>
                    <SummaryTemplateLabelHeader>User Stories</SummaryTemplateLabelHeader>
                </Row>

                <Row>
                    <SummaryTemplateDescription>Features or requirements to be completed</SummaryTemplateDescription>
                </Row>
            </Column>
            <Spacing factor={1 / 3} />
            <Column>
                <Row>
                    <SummaryTemplateLabelHeader>Tasks</SummaryTemplateLabelHeader>
                </Row>

                <Row>
                    <SummaryTemplateDescription>Specific work items to achieve user stories</SummaryTemplateDescription>
                </Row>
            </Column>
            <Spacing factor={1 / 3} />
            <Column>
                <Row>
                    <SummaryTemplateHeader>Estimates</SummaryTemplateHeader>
                </Row>

                <Row>
                    <SummaryTemplateDescription>Time or effort required for tasks</SummaryTemplateDescription>
                </Row>
            </Column>
            <Spacing factor={1 / 3} />
            <Column>
                <Row>
                    <SummaryTemplateLabelHeader>Blockers</SummaryTemplateLabelHeader>
                </Row>

                <Row>
                    <SummaryTemplateDescription>Potential obstacles to progress</SummaryTemplateDescription>
                </Row>
            </Column>
            <Spacing factor={1 / 3} />
            <Column>
                <Row>
                    <SummaryTemplateLabelHeader>Next Steps</SummaryTemplateLabelHeader>
                </Row>

                <Row>
                    <SummaryTemplateDescription>Actions to start the sprint</SummaryTemplateDescription>
                </Row>
            </Column>
        </Column>
    );
};

export const BacklogRefinementSummaryStructure = () => {
    return (
        <Column>
            <Row>
                <SummaryTemplateHeader>Backlog Refinement Summary Structure</SummaryTemplateHeader>
            </Row>
            <Spacing factor={1 / 3} />
            <Column>
                <Row>
                    <SummaryTemplateLabelHeader>Items Reviewed</SummaryTemplateLabelHeader>
                </Row>

                <Row>
                    <SummaryTemplateDescription>Backlog items discussed</SummaryTemplateDescription>
                </Row>
            </Column>
            <Spacing factor={1 / 3} />
            <Column>
                <Row>
                    <SummaryTemplateLabelHeader>Prioritization Decisions</SummaryTemplateLabelHeader>
                </Row>

                <Row>
                    <SummaryTemplateDescription>Ranking of items</SummaryTemplateDescription>
                </Row>
            </Column>
            <Spacing factor={1 / 3} />
            <Column>
                <Row>
                    <SummaryTemplateLabelHeader>Estimates and Re-estimates</SummaryTemplateLabelHeader>
                </Row>

                <Row>
                    <SummaryTemplateDescription>Time/effort assessment of work</SummaryTemplateDescription>
                </Row>
            </Column>
            <Spacing factor={1 / 3} />
            <Column>
                <Row>
                    <SummaryTemplateLabelHeader>Dependencies and Risks</SummaryTemplateLabelHeader>
                </Row>

                <Row>
                    <SummaryTemplateDescription>Related tasks and potential issues</SummaryTemplateDescription>
                </Row>
            </Column>
            <Spacing factor={1 / 3} />
            <Column>
                <Row>
                    <SummaryTemplateLabelHeader>
                        {ScribeFeatureIntentLabelMap[FeatureIntent.MeetingActionItems]}
                    </SummaryTemplateLabelHeader>
                </Row>

                <Row>
                    <SummaryTemplateDescription>
                        {FEATURE_INTENT_DESCRIPTION[FeatureIntent.MeetingActionItems]}
                    </SummaryTemplateDescription>
                </Row>
            </Column>
            <Spacing factor={1 / 3} />
            <Column>
                <Row>
                    <SummaryTemplateLabelHeader>Additional Notes and Observations</SummaryTemplateLabelHeader>
                </Row>

                <Row>
                    <SummaryTemplateDescription>Extra insights and comments</SummaryTemplateDescription>
                </Row>
            </Column>
            <Spacing factor={1 / 3} />
            <Column>
                <Row>
                    <SummaryTemplateLabelHeader>
                        {ScribeFeatureIntentLabelMap[FeatureIntent.MeetingBlockers]}
                    </SummaryTemplateLabelHeader>
                </Row>

                <Row>
                    <SummaryTemplateDescription>
                        {FEATURE_INTENT_DESCRIPTION[FeatureIntent.MeetingBlockers]}
                    </SummaryTemplateDescription>
                </Row>
            </Column>
            <Spacing factor={1 / 3} />
            <Column>
                <Row>
                    <SummaryTemplateLabelHeader>Next Steps</SummaryTemplateLabelHeader>
                </Row>

                <Row>
                    <SummaryTemplateDescription>Follow-up actions to be taken</SummaryTemplateDescription>
                </Row>
            </Column>
        </Column>
    );
};

const SummaryExampleTemplateSection = ({
    featureIntentLabel,
    featureIntentDescription,
}: {
    featureIntentLabel: string;
    featureIntentDescription: string | { label: string; description: string }[] | Record<string, string>;
}) => {
    return (
        <>
            <Column>
                <Row>
                    <SummaryTemplateLabelHeader>{featureIntentLabel}</SummaryTemplateLabelHeader>
                </Row>
                {typeof featureIntentDescription === 'string' ? (
                    <Row>
                        <SummaryTemplateDescription>{featureIntentDescription}</SummaryTemplateDescription>
                    </Row>
                ) : Array.isArray(featureIntentDescription) ? (
                    featureIntentDescription.map(({ label, description }, index) => (
                        <NestedSummaryExampleTemplate
                            key={`${featureIntentLabel}-${index}-${label}`}
                            label={label}
                            description={description}
                        />
                    ))
                ) : (
                    <></>
                )}
            </Column>
            <Spacing factor={1 / 3} />
        </>
    );
};

export const SummaryExampleTemplate = ({ meetingType }: { meetingType: ScribeMeetingType }) => {
    if (meetingType === ScribeMeetingType.BacklogGrooming) {
        return <BacklogRefinementSummaryStructure />;
    }
    if (meetingType === ScribeMeetingType.SprintPlanning) {
        return <PlanningSummaryStructure />;
    }
    return (
        <Column>
            <Row>
                <SummaryTemplateHeader
                    style={{
                        justifyContent: 'start',
                        alignItems: 'start',
                    }}
                >
                    {ScribeMeetingTypeLabelMap[meetingType]} Summary Structure
                </SummaryTemplateHeader>
            </Row>
            <Spacing factor={1 / 2} />
            {Object.values(MEETING_TYPE_FEATURE_INTENT_MAP[meetingType]).map((featureIntent, index) => {
                const featureIntentLabel = ScribeFeatureIntentLabelMap[featureIntent];

                if (typeof featureIntentLabel === 'string') {
                    const featureIntentDescription = FEATURE_INTENT_DESCRIPTION[featureIntent];
                    return (
                        <SummaryExampleTemplateSection
                            key={`${featureIntentLabel}-${featureIntentDescription}-${index}`}
                            featureIntentLabel={featureIntentLabel}
                            featureIntentDescription={featureIntentDescription}
                        />
                    );
                }

                return Object.entries(featureIntentLabel).map(
                    ([nestedFeatureIntentKey, nestedFeatureIntentLabel], index) => {
                        const featureIntentDescription =
                            FEATURE_INTENT_DESCRIPTION[nestedFeatureIntentKey as NestedSummaryFeatureIntentKeys];

                        return (
                            <SummaryExampleTemplateSection
                                key={`${featureIntentLabel}-${nestedFeatureIntentKey}-${index}`}
                                featureIntentLabel={nestedFeatureIntentLabel}
                                featureIntentDescription={featureIntentDescription}
                            />
                        );
                    }
                );
            })}
        </Column>
    );
};
