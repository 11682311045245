import React from 'react';

import { DashboardSection } from '@spinach-shared/constants';
import { StoredSpinachSeries } from '@spinach-shared/utils';

import { useGlobalAiDashboard, useGlobalAuthedUser, useWindowSize } from '../../../hooks';
import { Column } from '../../common';
import { AccountSection } from './AccountManagement/AccountSection';
import { AdminSection } from './admin';
import { DraftsSection } from './drafts/DraftsSection';
import { SettingsSection } from './integrations/SettingsSection';
import { MeetingSectionWithoutCalendarIntegration } from './meetings';
import { CombinedMeetingSection } from './meetings/CombinedMeetingSection';
import { TranscriptsSection } from './summaries';
import { AsyncVideoSection } from './summaries/AsyncVideo';
import { ActiveSectionComponent } from './types';

export function ActiveSection({
    activeSection,
    setModalKind,
    storedSeriesList,
    isAddingAdditionalMeetings,
    setIsAddingAdditionalMeetings,
    setStoredSeriesList,
    setModalMetadata,
}: ActiveSectionComponent & {
    isAddingAdditionalMeetings: boolean;
    setIsAddingAdditionalMeetings: (value: boolean) => void;
    setStoredSeriesList: (value: StoredSpinachSeries[]) => void;
}): JSX.Element {
    let component: JSX.Element;
    const { width } = useWindowSize();
    const [user] = useGlobalAuthedUser();
    const {
        state: { lastRefreshedHistory },
    } = useGlobalAiDashboard();
    const isDesktopView = width > 800;

    switch (activeSection) {
        case DashboardSection.Meetings:
            component = user.isAuthedForAnyCalendar ? (
                <CombinedMeetingSection
                    storedSeriesList={storedSeriesList}
                    setStoredSeriesList={setStoredSeriesList}
                    setModalKind={setModalKind}
                />
            ) : (
                <MeetingSectionWithoutCalendarIntegration
                    storedSeriesList={storedSeriesList.filter((s) => s.isScribeSeriesVisible)}
                    isAddingAdditionalMeetings={isAddingAdditionalMeetings}
                    setIsAddingAdditionalMeetings={setIsAddingAdditionalMeetings}
                    setStoredSeriesList={setStoredSeriesList}
                    setModalKind={setModalKind}
                />
            );
            break;
        case DashboardSection.Integrations:
            component = <SettingsSection setModalKind={setModalKind} setModalMetadata={setModalMetadata} />;
            break;
        case DashboardSection.Transcripts:
        case DashboardSection.Summaries:
        case DashboardSection.Actions:
        case DashboardSection.AskSpinach:
            component = <TranscriptsSection key={lastRefreshedHistory} />;
            break;
        case DashboardSection.Account:
            component = <AccountSection />;
            break;
        case DashboardSection.Admin:
            component = <AdminSection />;
            break;
        case DashboardSection.Video:
            component = <AsyncVideoSection />;
            break;
        case DashboardSection.Drafts:
            component = <DraftsSection />;
            break;
        default:
            component = user.isAuthedForAnyCalendar ? (
                <CombinedMeetingSection
                    storedSeriesList={storedSeriesList}
                    setStoredSeriesList={setStoredSeriesList}
                    setModalKind={setModalKind}
                />
            ) : (
                <MeetingSectionWithoutCalendarIntegration
                    storedSeriesList={storedSeriesList}
                    isAddingAdditionalMeetings={isAddingAdditionalMeetings}
                    setIsAddingAdditionalMeetings={setIsAddingAdditionalMeetings}
                    setStoredSeriesList={setStoredSeriesList}
                    setModalKind={setModalKind}
                />
            );
            break;
    }
    return (
        <Column style={{ height: '100%', width: isDesktopView ? 'calc(100% - 260px)' : '100%' }}>{component}</Column>
    );
}
