import { Box } from '@material-ui/core';
import { CallMade, InfoOutlined, Label } from '@material-ui/icons';
import React, { useState } from 'react';

import { ClientUser } from '@spinach-shared/models';
import {
    ClientEventType,
    FeatureToggle,
    IClientUser,
    KnowledgeBaseIntegration,
    SpinachAPIPath,
    SpinachIntegration,
    WebUrlQuery,
} from '@spinach-shared/types';

import { getUser, postSetupKnowledgeBaseSummary } from '../../../../..';
import DriveIlustration from '../../../../assets/drive-illustration.png';
import ConfluenceLogo from '../../../../assets/integrations/confluence-logo.png';
import GoogleDriveLogo from '../../../../assets/integrations/google-drive.png';
import NotionLogo from '../../../../assets/integrations/notion-logo.svg';
import { GlobalModal, GlobalModalMetadataType } from '../../../../atoms';
import {
    useDisabledKnowledgeBaseForFreeUsers,
    useExperienceTracking,
    useGlobalAuthedUser,
    useGlobalModal,
    useGoogleDriveEnablement,
    useIntegrationDetection,
    useProFeatures,
} from '../../../../hooks';
import { BodyRegularOnboard, ButtonSize, HeaderThree, lightTheme } from '../../../../styles';
import { URLUtil } from '../../../../utils';
import { Column, ProBadge, Row, Spacing, UpgradeLockIcon } from '../../../common';
import { OutlinedButton } from '../../../stand-up/OutlinedButton';
import { TooltipContainer } from '../../onboarding/step-tracker-onboarding/shared';
import { CustomTooltip } from '../meetings';
import { IntegrationSettingsModalKind, IntegrationSettingsModalMetadata, UseHoverRowProps } from '../types';
import { ConnectedRowContents } from './ConnectedRowContents';
import { ComingSoonBanner, IntegrationProps, IntegrationSection } from './IntegrationSection';
import { NotionPreAuthInstructionalModal } from './NotionPreAuthInstructionalModal';

export function GoogleDriveIntegrationSection({
    setModalKind,
    hoverRowProps,
    isComingSoon = false,
    locked,
}: {
    setModalKind: (modalKind: IntegrationSettingsModalKind) => void;
    hoverRowProps: UseHoverRowProps;
    isComingSoon?: boolean;
    locked: boolean;
}): JSX.Element {
    const isDriveEnabled = useGoogleDriveEnablement();
    const [user, setUser] = useGlobalAuthedUser();
    const { getHoverableRowProps, hoveredRow } = hoverRowProps;
    const track = useExperienceTracking();
    const [_, setGlobalModal] = useGlobalModal();

    const startDetection = useIntegrationDetection((updatedUser) => {
        let updatedKnowledgeBaseIntegration: KnowledgeBaseIntegration | undefined;

        if (!user.isAuthedForGoogleDrive && updatedUser.isAuthedForGoogleDrive) {
            updatedKnowledgeBaseIntegration = SpinachIntegration.GoogleDrive;
        }

        if (updatedKnowledgeBaseIntegration) {
            const setup = async (knowledgeBaseIntegration: KnowledgeBaseIntegration) => {
                const response = await postSetupKnowledgeBaseSummary({ knowledgeBaseIntegration });
                const userResponse = await getUser();
                if (userResponse.user) {
                    setUser(userResponse.user);
                }

                setGlobalModal(GlobalModal.KnowledgeBaseSetupComplete, {
                    metadata: {
                        metadataType: GlobalModalMetadataType.KnowledgeBaseSetup,
                        knowledgeBaseIntegration,
                        setupFailed: !response || !!response?.setupFailed,
                    },
                });
            };
            setup(updatedKnowledgeBaseIntegration);
        }
    });

    const isGoogleDriveVideoUploadEnabled = user.featureToggles[FeatureToggle.UploadVideoToGoogleDrive];
    return isDriveEnabled ? (
        <>
            <Row vCenter {...getHoverableRowProps(SpinachIntegration.GoogleDrive)}>
                {locked ? (
                    <UpgradeLockIcon analyticsPrefix="Google drive" />
                ) : (
                    <img src={GoogleDriveLogo} style={{ width: '24px', height: '24px', marginRight: '15px' }} />
                )}
                <Label
                    style={{
                        opacity: locked ? 0.5 : 1,
                        width: isGoogleDriveVideoUploadEnabled ? '80px' : undefined,
                    }}
                >
                    Google
                </Label>
                {isGoogleDriveVideoUploadEnabled ? (
                    <Box width="95px" display="flex">
                        <CustomTooltip
                            enterTouchDelay={0}
                            leaveTouchDelay={15000}
                            onOpen={() => {
                                track(ClientEventType.AIDashboardActivity, {
                                    ClickedOn: `Google Drive info tooltip`,
                                });
                            }}
                            arrow
                            style={{ marginLeft: '5px' }}
                            interactive={true}
                            title={
                                <TooltipContainer
                                    style={{
                                        width: '300px',
                                        flexDirection: 'column',
                                    }}
                                >
                                    <Row centered>
                                        <img src={DriveIlustration} style={{ width: '300px', marginBottom: '10px' }} />
                                    </Row>
                                    <Row>
                                        <Column>
                                            <BodyRegularOnboard
                                                style={{
                                                    fontSize: '16px',
                                                    textAlign: 'left',
                                                }}
                                            >
                                                Store meeting summaries and video recordings to your Google Drive
                                            </BodyRegularOnboard>
                                        </Column>
                                    </Row>
                                </TooltipContainer>
                            }
                            placement="top"
                        >
                            <InfoOutlined
                                style={{ width: '24px', height: '24px' }}
                                htmlColor={lightTheme.primary.greenLight}
                            />
                        </CustomTooltip>
                        <Box
                            alignSelf={'center'}
                            display="flex"
                            py="2px"
                            px="8px"
                            ml="8px"
                            style={{
                                background: '#0D674A',
                                color: 'white',
                                fontWeight: 'bold',
                                borderRadius: '2px',
                                fontSize: '14px',
                            }}
                        >
                            <Box justifySelf="center" style={{ display: 'flex', alignItems: 'center', height: 'auto' }}>
                                New
                            </Box>
                        </Box>
                    </Box>
                ) : null}
                {user.isAuthedForGoogleDrive && !isComingSoon ? (
                    <ConnectedRowContents
                        label={'Connected'}
                        isSettingsVisible={hoveredRow === SpinachIntegration.GoogleDrive}
                        onSettingsClick={async () => {
                            setModalKind(IntegrationSettingsModalKind.SettingsGoogleDrive);
                            track(ClientEventType.AIDashboardClick, {
                                ClickedOn: `Open Remove Google Drive Modal`,
                            });
                        }}
                    />
                ) : isComingSoon ? (
                    <ComingSoonBanner />
                ) : locked ? (
                    <></>
                ) : (
                    <OutlinedButton
                        title={'Connect'}
                        onClick={() => {
                            track(ClientEventType.AIDashboardClick, {
                                ClickedOn: `Google Drive Connect`,
                            });
                            track(ClientEventType.UserAuthorizeGoogleDriveClick);
                            startDetection();
                            URLUtil.openURL(`${process.env.REACT_APP_AUTH_URL}${SpinachAPIPath.GoogleDriveAuth}`);
                        }}
                        size={ButtonSize.Mini}
                        endIcon={<CallMade style={{ fontSize: '14px', marginLeft: '5px' }} />}
                        labelStyles={{
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    />
                )}
            </Row>
        </>
    ) : (
        <></>
    );
}

export function KnowledgeBaseIntegrationSection({
    setModalKind,
    setModalMetadata,
    hoverRowProps,
}: {
    setModalKind: (modalKind: IntegrationSettingsModalKind) => void;
    setModalMetadata: (metadata: IntegrationSettingsModalMetadata) => void;
    hoverRowProps: UseHoverRowProps;
}): JSX.Element {
    const hasProFeatures = useProFeatures();
    const disabledKnowledgeBaseForFreeUsers = useDisabledKnowledgeBaseForFreeUsers();
    const locked = disabledKnowledgeBaseForFreeUsers && !hasProFeatures;
    const startDetection = useIntegrationDetection();
    const [isNotionPreAuthInstructionalModalOpen, setIsNotionPreAuthInstructionalModalOpen] = useState(false);
    const [_, setGlobalModal] = useGlobalModal();

    const knowledgeBaseIntegrations: IntegrationProps[] = [
        {
            integration: SpinachIntegration.Confluence,
            customLogo: ConfluenceLogo,
            customConnectedLabel: (user: IClientUser) =>
                user.integrationSettings?.notionSettings?.defaultDatabaseName
                    ? `Connected: ${user.integrationSettings?.notionSettings?.defaultDatabaseName}`
                    : 'Connected',
        },
        {
            integration: SpinachIntegration.Notion,
            customLogo: NotionLogo,
            onConnect: () => setIsNotionPreAuthInstructionalModalOpen(true),
            customDetection: (user: ClientUser, setUser: (user: IClientUser) => void) => (updatedUser: ClientUser) => {
                let updatedKnowledgeBaseIntegration: KnowledgeBaseIntegration | undefined;

                if (!user.isAuthedForNotion && updatedUser.isAuthedForNotion) {
                    updatedKnowledgeBaseIntegration = SpinachIntegration.Notion;
                }

                if (updatedKnowledgeBaseIntegration) {
                    const setup = async (knowledgeBaseIntegration: KnowledgeBaseIntegration) => {
                        const response = await postSetupKnowledgeBaseSummary({ knowledgeBaseIntegration });
                        const userResponse = await getUser();
                        if (userResponse.user) {
                            setUser(userResponse.user);
                        }

                        setGlobalModal(GlobalModal.KnowledgeBaseSetupComplete, {
                            metadata: {
                                metadataType: GlobalModalMetadataType.KnowledgeBaseSetup,
                                knowledgeBaseIntegration,
                                setupFailed: !response || !!response?.setupFailed,
                            },
                        });
                    };
                    setup(updatedKnowledgeBaseIntegration);
                }
            },
            customConnectedLabel: (user: IClientUser) =>
                user.integrationSettings?.notionSettings?.defaultDatabaseName
                    ? `Connected: ${user.integrationSettings?.notionSettings?.defaultDatabaseName}`
                    : 'Connected',
        },
    ];

    return (
        <>
            <Row>
                <HeaderThree>Knowledge Base</HeaderThree>
                {disabledKnowledgeBaseForFreeUsers ? <ProBadge /> : <></>}
            </Row>
            <Spacing factor={1 / 2} />
            <Row>
                <BodyRegularOnboard>Keep your summaries in a spot</BodyRegularOnboard>
            </Row>
            <Spacing factor={1 / 2} />
            {knowledgeBaseIntegrations.map((integration) => (
                <>
                    <IntegrationSection
                        integration={integration.integration}
                        setModalKind={setModalKind}
                        hoverRowProps={hoverRowProps}
                        locked={locked}
                        customLogo={integration.customLogo}
                        onConnect={integration.onConnect}
                        customConnectedLabel={integration.customConnectedLabel}
                    />
                    <Spacing factor={1 / 2} />
                </>
            ))}
            <GoogleDriveIntegrationSection setModalKind={setModalKind} hoverRowProps={hoverRowProps} locked={locked} />
            <NotionPreAuthInstructionalModal
                isOpen={isNotionPreAuthInstructionalModalOpen}
                setIsOpen={setIsNotionPreAuthInstructionalModalOpen}
                onConfirm={(user: IClientUser) => {
                    startDetection();

                    URLUtil.openURL(
                        `${process.env.REACT_APP_AUTH_URL}${SpinachAPIPath.IntegrationAuth.replace(
                            ':oauthProvider',
                            SpinachIntegration.Notion
                        )}?${WebUrlQuery.Suid}=${user._id}`,
                        { redirectThroughBackendWhenOnZoom: true }
                    );
                    setIsNotionPreAuthInstructionalModalOpen(false);
                }}
            />
        </>
    );
}
