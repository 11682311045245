import { CallMade, InfoOutlined } from '@material-ui/icons';
import React from 'react';
import styled from 'styled-components';

import { ClientUser } from '@spinach-shared/models';
import {
    ClientEventType,
    IClientUser,
    OAuthProvider,
    SpinachAPIPath,
    SpinachIntegration,
    SpinachIntegrations,
    WebUrlQuery,
} from '@spinach-shared/types';

import { useExperienceTracking, useGlobalAuthedUser, useIntegrationDetection } from '../../../../hooks';
import { BodyRegularOnboard, ButtonSize, lightTheme } from '../../../../styles';
import { URLUtil } from '../../../../utils';
import { Badge, BootstrapTooltip, Row, UpgradeLockIcon } from '../../../common';
import { OutlinedButton } from '../../../stand-up/OutlinedButton';
import { IntegrationSettingsModalKind, UseHoverRowProps } from '../types';
import { ConnectedRowContents } from './ConnectedRowContents';
import { Label } from './common';

const Banner = styled(BodyRegularOnboard)`
    font-weight: 700;
    padding-left: 4px;
    padding-right: 4px;
    background-color: ${() => lightTheme.secondary.greenLight};
    color: ${() => lightTheme.neutrals.white};
`;

export const ComingSoonBanner = () => <Banner>Coming Soon!</Banner>;

export type IntegrationProps = {
    integration: OAuthProvider;
    isComingSoon?: boolean;
    locked?: boolean;
    customLogo?: JSX.Element | string;
    customTooltip?: React.ReactNode;
    isNew?: boolean;
    customConnectedLabel?: (user: any) => string;
    onConnect?: () => void;
    customDetection?: (
        user: ClientUser,
        setUser: (user: IClientUser) => void
    ) => ((updatedUser: ClientUser) => void) | ((updatedUser: ClientUser) => Promise<void>);
};

export type IntegrationSectionProps = IntegrationProps & {
    setModalKind: (modalKind: IntegrationSettingsModalKind) => void;
    hoverRowProps: UseHoverRowProps;
};

export function IntegrationSection({
    integration,
    setModalKind,
    hoverRowProps,
    isComingSoon = false,
    locked = false,
    customDetection,
    customLogo,
    customTooltip,
    isNew,
    customConnectedLabel,
    onConnect,
}: IntegrationSectionProps): JSX.Element {
    const [user, setUser] = useGlobalAuthedUser();
    const { getHoverableRowProps, hoveredRow } = hoverRowProps;
    const track = useExperienceTracking();
    const customDetectionMethod:
        | ((updatedUser: ClientUser) => void)
        | ((updatedUser: ClientUser) => Promise<void>)
        | undefined = customDetection ? customDetection(user, setUser) : undefined;
    const startDetection = useIntegrationDetection(customDetectionMethod);

    const integrationConfig = SpinachIntegrations[integration];
    const isAuthedForIntegration = user.isAuthedForIntegration(integration);

    const handleConnect = () => {
        track(ClientEventType.AIDashboardClick, {
            ClickedOn: `${integrationConfig.displayName} Connect`,
        });
        startDetection();
        if (onConnect) {
            onConnect();
        } else {
            URLUtil.openURL(
                `${process.env.REACT_APP_AUTH_URL}${SpinachAPIPath.IntegrationAuth.replace(
                    ':oauthProvider',
                    integration
                )}?${WebUrlQuery.Suid}=${user.spinachUserId}`,
                { redirectThroughBackendWhenOnZoom: true }
            );
        }
    };

    return (
        <Row vCenter {...getHoverableRowProps(integration)}>
            {locked ? (
                <UpgradeLockIcon analyticsPrefix={integrationConfig.displayName} />
            ) : customLogo ? (
                typeof customLogo === 'string' ? (
                    <img src={customLogo} style={{ width: '24px', height: '24px', marginRight: '15px' }} />
                ) : (
                    customLogo
                )
            ) : null}
            <Label style={{ opacity: locked ? 0.5 : 1, display: 'flex', alignItems: 'center' }}>
                {integrationConfig.displayName}
                {customTooltip ? (
                    <BootstrapTooltip
                        interactive
                        placement="top"
                        PopperProps={{
                            style: {
                                border: `1px ${lightTheme.neutrals.grayDark} solid`,
                            },
                        }}
                        arrowStyle={{
                            border: `1px ${lightTheme.neutrals.grayDark} solid`,
                            backgroundColor: lightTheme.neutrals.white,
                            color: lightTheme.neutrals.white,
                        }}
                        tooltipStyle={{
                            maxWidth: 'unset',
                            padding: '25px',
                            margin: 'unset',
                            backgroundColor: lightTheme.neutrals.white,
                        }}
                        title={customTooltip}
                    >
                        <InfoOutlined
                            style={{ marginLeft: '5px', color: lightTheme.primary.greenLight }}
                            htmlColor={lightTheme.primary.greenLight}
                        />
                    </BootstrapTooltip>
                ) : null}
                {isNew ? <Badge title="New" /> : null}
            </Label>

            {isAuthedForIntegration && !isComingSoon ? (
                <ConnectedRowContents
                    label={customConnectedLabel ? customConnectedLabel(user) : 'Connected'}
                    isSettingsVisible={hoveredRow === integration}
                    onSettingsClick={async () => {
                        setModalKind(
                            IntegrationSettingsModalKind[
                                `Settings${
                                    integration.slice(0, 1).toUpperCase() + integration.slice(1)
                                }` as keyof typeof IntegrationSettingsModalKind
                            ]
                        );
                        track(ClientEventType.AIDashboardClick, {
                            ClickedOn: `Open ${integrationConfig.displayName} Settings Modal`,
                        });
                    }}
                />
            ) : isComingSoon ? (
                <ComingSoonBanner />
            ) : locked ? (
                <></>
            ) : (
                <OutlinedButton
                    title={'Connect'}
                    onClick={handleConnect}
                    size={ButtonSize.Mini}
                    endIcon={<CallMade style={{ fontSize: '14px', marginLeft: '5px' }} />}
                    labelStyles={{
                        display: 'flex',
                        alignItems: 'center',
                    }}
                />
            )}
        </Row>
    );
}
