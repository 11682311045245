import { Box, IconButton, Tooltip, makeStyles } from '@material-ui/core';
import { ExpandMore, InfoOutlined, Today } from '@material-ui/icons';
import { Create, ToggleOn } from '@material-ui/icons';
import CachedIcon from '@material-ui/icons/Cached';
import { useEffect, useState } from 'react';
import styled from 'styled-components';

import { CalendarEvent, ClientEventType, EmailDistributionConfig } from '@spinach-shared/types';

import {
    BodyRegularOnboard,
    HeaderThreeOnboard,
    MeetingsAndSuggestions,
    PrimaryButton,
    Spacing,
    lightTheme,
    useExperienceTracking,
    useWindowSize,
} from '../../../../..';
import { ReactComponent as CheckIcon } from '../../../../assets/check-icon.svg';
import { ReactComponent as SpinachLogo } from '../../../../assets/logo-spinachio.svg';
import { useGlobalAiMeetingSelection, useGlobalAuthedUser } from '../../../../hooks';
import { isScribeEmail } from '../../../../hooks/useScribe';
import { BodyBigOnboard, BodyRegular } from '../../../../styles';
import { withContentMasking } from '../../../../utils';
import { Row } from '../../../common';
import { BootstrapTooltip, Column } from '../../../common';
import { SpinachSwitch } from '../../../common/SpinachSwitch';
import { ScrollView } from '../../../series';
import SecondaryButton from '../../../stand-up/SecondaryButton';
import './SteTrackerAddToMeetingStep.css';
import { StepTracker } from './StepTracker';
import { getString, onboardingSteps } from './constants';
import { Container, ContentContainer, CustomTooltip, Divider, OutlineButton, TooltipContainer } from './shared';

export type StepTrackerAddToMeetingsStepProps = {
    isFetchingEvents: boolean;
    refetchEvents: () => void;
    isRefetchingEvents: boolean;
    hasError: boolean;
    onEventClick: (event: CalendarEvent) => void;
    data: MeetingsAndSuggestions | undefined;
    onboardingEventsToAddScribeTo: CalendarEvent[];
    hadErrorWhileAddingScribeToEvents: boolean;
    onContinue: () => Promise<void>;
    loadingMessage: string;
    editSummariesBeforeTheyAreSent: boolean;
    setEditSummariesBeforeTheyAreSent: (value: boolean) => void;
    isEmailingIcpOnly: boolean;
    setIsEmailingIcpOnly: (value: boolean) => void;
    isAgentOnboarding: boolean;
    emailDistributionConfig: EmailDistributionConfig;
    setEmailDistributionConfig: (value: EmailDistributionConfig) => void;
};

const MeetingContainer = styled.li<{ isInteractive: boolean; isHighlighted?: boolean }>`
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
    border: 1px solid #eaeaea;
    box-shadow: ${(props) =>
        props.isHighlighted ? `0px 0px 5px ${props.theme.primary.orangeLight}` : '3px 3px 8px 2px rgba(0, 0, 0, 0.1)'};
    border-radius: 6px;
    width: 100%;
    padding: 15px;
    box-sizing: border-box;
    background-color: white;
    cursor: ${(props) => (props.isInteractive ? 'pointer' : 'default')};
`;

const MeetingsContainer = styled.ul`
    margin: 0;
    width: 100%;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`;
const MeetingsScrollView = styled(ScrollView)<{ hasError: boolean }>`
    position: relative;
    padding: 0;
    ${(props) => props.hasError && 'overflow:hidden;'}
    background: transparent;
`;

const MeetingsScrollViewContainer = styled.div`
    flex: 1;
    display: flex;
    position: relative;
    overflow: hidden;
    width: 100%;
`;

export const MeetingCheck = styled.div<{ isChecked: boolean; isInteractive: boolean; isHovered?: boolean }>`
    width: 30px;
    height: 30px;
    background: ${(props) =>
        props.isChecked || props.isHovered ? props.theme.secondary.green : props.theme.neutrals.gray};
    justify-self: flex-end;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    margin-left: 10px;
    opacity: ${(props) => (props.isHovered && !props.isChecked ? 0.2 : 1)};
    cursor: ${(props) => (!props.isInteractive ? 'not-allowed' : 'pointer')};
`;

export const InnerContentContainer = styled.div`
    max-width: 497px;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
`;

const ErrorContainer = styled(Row)`
    background: #f6cacb;
    padding: 10px;
    border-radius: 4px;
    font-size: 18px;
    box-sizing: border-box;
`;

const useTooltipStyles = makeStyles(() => ({
    tooltip: {
        background: 'black',
        fontSize: '15px',
        padding: '10px',
    },
    arrow: {
        color: 'black',
    },
}));

export function getScribeAttendeesFromEvent(event: CalendarEvent) {
    return event.attendees?.filter((attendee) => isScribeEmail(attendee.email));
}

function MeetingTime({ event }: { event: CalendarEvent }): JSX.Element {
    if (!event.start?.dateTime || !event.end?.dateTime || !event.start?.timeZone || !event.end?.timeZone) {
        return <></>;
    }

    const userLocale = navigator.language;

    const startString = new Date(event.start.dateTime)
        .toLocaleTimeString(userLocale, {
            hour: 'numeric',
            minute: 'numeric',
            hour12: true,
        })
        .toLowerCase();
    const endString = new Date(event.end.dateTime)
        .toLocaleTimeString(userLocale, {
            hour: 'numeric',
            minute: 'numeric',
            hour12: true,
        })
        .toLowerCase();

    return (
        <Row style={{ fontSize: '15px', marginTop: '5px', width: '100%' }} vCenter>
            <span style={{ marginLeft: '12px' }}>{`${startString} - ${endString}`}</span>
        </Row>
    );
}

function MeetingItem({
    event,
    isInteractive,
    isChecked,
    isEventHovered,
    nonInteractiveTooltip,
}: {
    event: CalendarEvent;
    showTooltip: boolean;
    isInteractive: boolean;
    isChecked: boolean;
    isOrganizer?: boolean;
    isEventHovered?: boolean;
    nonInteractiveTooltip?: string;
}): JSX.Element {
    const numberOfParticipantsWithoutScribe = event.attendees?.filter(
        (attendee) => !isScribeEmail(attendee.email)
    ).length;
    return (
        <>
            <Row style={{ alignItems: 'center' }}>
                <div style={{ flex: 1 }}>
                    <Row style={{}} vCenter>
                        <div
                            style={{
                                fontSize: '18px',
                                fontWeight: 'bold',
                                whiteSpace: 'nowrap',
                                maxWidth: '230px',
                                textOverflow: 'ellipsis',
                                overflowX: 'clip',
                            }}
                            title={event.summary ?? undefined}
                            {...withContentMasking()}
                        >
                            {event.summary}
                        </div>
                        <MeetingTime event={event} />
                    </Row>
                    {!!numberOfParticipantsWithoutScribe ? (
                        <Row style={{ fontSize: '12px', marginTop: '5px', fontWeight: 'bold' }}>
                            <span style={{ marginRight: '5px' }}>{numberOfParticipantsWithoutScribe} Participants</span>
                        </Row>
                    ) : null}
                </div>

                <BootstrapTooltip
                    title={
                        nonInteractiveTooltip ? (
                            <BodyRegular style={{ color: 'white' }}>{nonInteractiveTooltip}</BodyRegular>
                        ) : (
                            ''
                        )
                    }
                >
                    {!isInteractive ? (
                        <InfoOutlined
                            style={{ color: lightTheme.primary.greenLight }}
                            htmlColor={lightTheme.primary.greenLight}
                        />
                    ) : (
                        <MeetingCheck isChecked={isChecked} isInteractive={isInteractive} isHovered={isEventHovered}>
                            {isChecked ? (
                                <CheckIcon
                                    stroke={'white'}
                                    style={{
                                        width: '66%',
                                        height: 'auto',
                                        filter:
                                            isEventHovered && isInteractive && !isChecked
                                                ? 'brightness(0.8)'
                                                : undefined,
                                    }}
                                />
                            ) : (
                                <></>
                            )}
                            {!!isEventHovered && !isChecked ? (
                                <CheckIcon stroke={'white'} style={{ width: '66%', height: 'auto', opacity: 1 }} />
                            ) : (
                                <></>
                            )}
                        </MeetingCheck>
                    )}
                </BootstrapTooltip>
            </Row>
        </>
    );
}

export function SelectCalendarEventsForSpinach({
    hasError,
    onEventClick,
    relevantEvents,
    onboardingEventsToAddScribeTo,
    meetingsAndSuggestions,
}: {
    hasError: boolean;
    highlightedMeetingICalUid?: string;
    onEventClick: (event: CalendarEvent) => void;
    relevantEvents: CalendarEvent[];
    onboardingEventsToAddScribeTo: CalendarEvent[];
    meetingsAndSuggestions?: MeetingsAndSuggestions;
}): JSX.Element {
    const [user] = useGlobalAuthedUser();
    const [showAllEvents, setShowAllEvents] = useState(false);
    const [eventHovered, setEventHovered] = useState<null | string>(null);
    const {
        openedMeetingsState: { highlightedMeetingDetails },
    } = useGlobalAiMeetingSelection();

    const highlightedMeetingICalUid = highlightedMeetingDetails?.iCalUid;

    function mapEventRows(eventsToRender: CalendarEvent[]) {
        return eventsToRender.map((event) => {
            const isOrganizer = user.isUserTheOrganizer(event);
            const isScribeOn = event.isScribeOnEvent;
            const isInteractive = !isScribeOn && (!user.metadata.isUsingRecallCalendarV2 || !!event.meetingUrl); // for users using recall v2 we have to have a meeting url to be able to add spinach
            const nonInteractiveTooltip = isScribeOn
                ? 'Spinach is already on this meeting. You can adjust this later'
                : !event.meetingUrl
                ? 'Spinach requires a video conference link'
                : '';
            return (
                <MeetingContainer
                    id={`meeting-${event.iCalUID}`}
                    onMouseEnter={() => {
                        if (event.iCalUID) {
                            setEventHovered(event.iCalUID);
                        }
                    }}
                    onMouseLeave={() => {
                        setEventHovered(event.iCalUID!);
                    }}
                    isHighlighted={Boolean(highlightedMeetingICalUid && highlightedMeetingICalUid === event.iCalUID)}
                    isInteractive={isInteractive}
                    key={event.iCalUID}
                    onClick={() => {
                        onEventClick(event);
                    }}
                >
                    <MeetingItem
                        event={event}
                        isEventHovered={eventHovered === event.iCalUID}
                        isChecked={isScribeOn || onboardingEventsToAddScribeTo.includes(event)}
                        isInteractive={isInteractive}
                        nonInteractiveTooltip={nonInteractiveTooltip}
                        showTooltip={isOrganizer || !isInteractive}
                    />
                </MeetingContainer>
            );
        });
    }

    let CalendarSelection;
    if (meetingsAndSuggestions?.suggestionsWithoutSpinach.length) {
        CalendarSelection = (
            <>
                {meetingsAndSuggestions.suggestionsWithoutSpinach ? (
                    <>{mapEventRows(meetingsAndSuggestions.suggestionsWithoutSpinach)}</>
                ) : null}
                {meetingsAndSuggestions.eventsWithoutSuggestions.length ? (
                    <>
                        {showAllEvents && !!meetingsAndSuggestions.eventsWithoutSuggestions.length ? null : (
                            <SecondaryButton
                                title={
                                    <Row vCenter>
                                        <div>Other Meetings</div>
                                        <ExpandMore style={{ marginLeft: '5px', transform: 'rotate(-90deg)' }} />
                                    </Row>
                                }
                                onClick={() => {
                                    setShowAllEvents(true);
                                }}
                                buttonStyles={{
                                    textDecoration: 'none',
                                }}
                                labelStyles={{
                                    fontSize: '18px',
                                }}
                            />
                        )}
                        {showAllEvents ? mapEventRows(meetingsAndSuggestions.suggestionsWithSpinach) : null}
                        {showAllEvents ? mapEventRows(meetingsAndSuggestions.eventsWithoutSuggestions) : null}
                    </>
                ) : null}
            </>
        );
    } else {
        CalendarSelection = mapEventRows(relevantEvents);
    }

    useEffect(() => {
        if (highlightedMeetingICalUid) {
            document.getElementById(`meeting-${highlightedMeetingICalUid}`)?.scrollIntoView({ behavior: 'smooth' });
        }
    }, [highlightedMeetingICalUid]);

    return (
        <MeetingsScrollViewContainer>
            <MeetingsScrollView hasError={hasError}>
                <MeetingsContainer onMouseLeave={() => setEventHovered(null)}>{CalendarSelection}</MeetingsContainer>
            </MeetingsScrollView>
        </MeetingsScrollViewContainer>
    );
}

function ProBadge() {
    const tooltipClasses = useTooltipStyles();
    const [user] = useGlobalAuthedUser();
    const proBadgeFeatureTooltip = user.isOnLiveReverseTrial
        ? 'This is Pro feature included in your trial'
        : user.hasProFeatures
        ? 'This is Pro feature included in your plan'
        : 'Upgrade to Pro on your Account page to unlock advanced features and integrations.';

    return (
        <Tooltip
            enterTouchDelay={0}
            leaveTouchDelay={15000}
            style={{ marginLeft: '8px' }}
            classes={tooltipClasses}
            interactive={true}
            placement="top"
            arrow
            title={
                <BodyRegularOnboard style={{ fontSize: '15px', color: 'white' }}>
                    {proBadgeFeatureTooltip}
                </BodyRegularOnboard>
            }
        >
            <Box
                alignSelf={'center'}
                display="flex"
                py="2px"
                px="8px"
                ml="15px"
                style={{
                    background: '#0D674A',
                    color: 'white',
                    fontWeight: 'bold',
                    borderRadius: '2px',
                    fontSize: '12px',
                    padding: '2px 8px',
                    whiteSpace: 'nowrap',
                }}
            >
                <Box justifySelf="center" style={{ display: 'flex', alignItems: 'center', height: 'auto' }}>
                    {user.hasProFeatures ? 'Pro' : 'Upgrade to Pro'}
                </Box>
            </Box>
        </Tooltip>
    );
}

export function StepTrackerAddToMeetingsStep({
    hasError,
    data,
    onEventClick,
    onboardingEventsToAddScribeTo,
    isFetchingEvents,
    isRefetchingEvents,
    refetchEvents,
    onContinue,
    loadingMessage,
    editSummariesBeforeTheyAreSent,
    setEditSummariesBeforeTheyAreSent,
    isEmailingIcpOnly,
    setIsEmailingIcpOnly,
    hadErrorWhileAddingScribeToEvents,
    isAgentOnboarding,
    emailDistributionConfig,
    setEmailDistributionConfig,
}: StepTrackerAddToMeetingsStepProps): JSX.Element {
    const eventsToDisplay = data?.events;
    const [user] = useGlobalAuthedUser();

    const { width, isDesktopView } = useWindowSize();
    const [addToMeetingsTooltipOpen, setAddToMeetingsTooltipOpen] = useState(false);
    const track = useExperienceTracking();
    const noEventsFound = eventsToDisplay?.length === 0;

    return (
        <Container>
            <Row>
                <SpinachLogo
                    style={{
                        height: isDesktopView ? '55px' : '25px',
                    }}
                />
            </Row>

            <ContentContainer>
                <Row centered>
                    <StepTracker steps={onboardingSteps} currentStep={2} />
                </Row>
                <Spacing factor={2.5} />
                <HeaderThreeOnboard>{getString('AddToMeetingsTitle', isAgentOnboarding)}</HeaderThreeOnboard>
                <InnerContentContainer>
                    <Spacing factor={1 / 2} />
                    <Row vCenter>
                        <BodyRegularOnboard style={{ fontSize: '18px' }}>Add to meetings</BodyRegularOnboard>
                        <CustomTooltip
                            enterTouchDelay={0}
                            leaveTouchDelay={15000}
                            onOpen={() => {
                                if (!addToMeetingsTooltipOpen) {
                                    setAddToMeetingsTooltipOpen(true);
                                    track(ClientEventType.UserOpenedAddToMeetingsStepTrackerTooltip, {
                                        IsAgentOnboarding: isAgentOnboarding,
                                    });
                                }
                            }}
                            onClose={() => {
                                setAddToMeetingsTooltipOpen(false);
                            }}
                            style={{ marginLeft: '8px' }}
                            interactive={true}
                            title={
                                <TooltipContainer style={{ width: '400px' }}>
                                    <Column style={{ flex: 0 }}>
                                        <div
                                            style={{
                                                borderRadius: '5px',
                                                background: '#ECF1F0',
                                                padding: '10px',
                                                marginRight: '17px',
                                            }}
                                        >
                                            <Today
                                                htmlColor={lightTheme.primary.greenLight}
                                                style={{
                                                    width: '30px',
                                                    height: '30px',
                                                    color: lightTheme.primary.greenLight,
                                                }}
                                            />
                                        </div>
                                    </Column>
                                    <Column>
                                        <BodyRegularOnboard
                                            style={{ fontSize: '16px', textAlign: 'left', fontWeight: '700' }}
                                        >
                                            How Spinach works
                                        </BodyRegularOnboard>
                                        <Spacing factor={1 / 4} />
                                        <BodyRegularOnboard style={{ fontSize: '14px' }}>
                                            Spinach must be on a meeting to work. Spinach will join as a silent
                                            participant to take notes, and will send out an actionable summary once the
                                            meeting ends.
                                        </BodyRegularOnboard>

                                        <Spacing factor={1 / 4} />
                                        <Row vCenter>
                                            <Column style={{ flex: 0, marginRight: '10px' }}>
                                                <ToggleOn
                                                    style={{ color: lightTheme.primary.greenLight }}
                                                    htmlColor={lightTheme.primary.greenLight}
                                                />
                                            </Column>
                                            <Column style={{ alignItems: 'flex-start' }}>
                                                <BodyRegularOnboard style={{ fontSize: '14px' }}>
                                                    <span style={{ fontWeight: '700' }}>You control at anytime</span>{' '}
                                                    which meetings Spinach joins. Spinach will only join meetings you
                                                    opt intointo
                                                </BodyRegularOnboard>
                                            </Column>
                                        </Row>
                                        <Spacing factor={1 / 4} />
                                        <Row vCenter>
                                            <Column style={{ flex: 0, marginRight: '10px' }}>
                                                <Create
                                                    style={{ color: lightTheme.primary.greenLight }}
                                                    htmlColor={lightTheme.primary.greenLight}
                                                />
                                            </Column>
                                            <Column style={{ alignItems: 'flex-start' }}>
                                                <BodyRegularOnboard style={{ fontSize: '14px' }}>
                                                    <span style={{ fontWeight: '700' }}>Edit Summaries</span> before
                                                    they are sent
                                                </BodyRegularOnboard>
                                            </Column>
                                        </Row>
                                    </Column>
                                </TooltipContainer>
                            }
                            placement="right"
                        >
                            <InfoOutlined
                                htmlColor={lightTheme.primary.greenLight}
                                style={{ marginLeft: '10px' }}
                                fontSize="small"
                            />
                        </CustomTooltip>
                        <span style={{ flex: '1' }}></span>
                        <IconButton
                            onClick={async () => {
                                track(ClientEventType.StepTrackerAddToMeetingsRefreshButtonClicked, {
                                    IsAgentOnboarding: isAgentOnboarding,
                                });
                                await refetchEvents();
                            }}
                            disableRipple
                            disabled={isFetchingEvents || isRefetchingEvents}
                            className={isFetchingEvents || isRefetchingEvents ? 'refresh-upcoming-meetings' : undefined}
                        >
                            <CachedIcon htmlColor={lightTheme.primary.greenLight} fontSize="large" />
                        </IconButton>
                    </Row>
                    <ScrollView
                        sidePadding={0}
                        style={{ backgroundColor: 'rgba(0,0,0,0)', background: 'unset', maxHeight: '297px' }}
                    >
                        {!!eventsToDisplay?.length ? (
                            <>
                                <SelectCalendarEventsForSpinach
                                    hasError={hasError}
                                    onEventClick={onEventClick}
                                    relevantEvents={eventsToDisplay}
                                    onboardingEventsToAddScribeTo={onboardingEventsToAddScribeTo}
                                    meetingsAndSuggestions={data}
                                />
                            </>
                        ) : noEventsFound && !hasError ? (
                            <BodyRegularOnboard style={{ fontSize: '18px' }}>
                                We couldn’t find any meetings scheduled in the next few weeks. Create a meeting in your
                                calendar for Spinach to be added to.
                            </BodyRegularOnboard>
                        ) : null}
                    </ScrollView>
                    {hasError ? (
                        <ErrorContainer>
                            <BodyRegularOnboard>
                                We’re having some trouble with your calendar. Keep going and you can try again later.
                            </BodyRegularOnboard>
                        </ErrorContainer>
                    ) : (
                        <></>
                    )}

                    {hadErrorWhileAddingScribeToEvents ? (
                        <>
                            <Spacing factor={1 / 2} />
                            <ErrorContainer>
                                <BodyRegularOnboard>
                                    We’re having some trouble Adding Spinach to your calendar. Keep going and you can
                                    try again later.
                                </BodyRegularOnboard>
                            </ErrorContainer>
                        </>
                    ) : null}
                    <Spacing factor={1} />
                    <Divider />
                    <Spacing factor={1} />
                    <BodyRegularOnboard style={{ fontSize: '18px' }}>
                        {getString('WhoToSendSummariesTo', isAgentOnboarding)}
                    </BodyRegularOnboard>
                    <Spacing factor={1 / 2} />
                    <Row vCenter>
                        <Column>
                            <Row style={{ flexWrap: 'wrap', gap: '10px' }}>
                                <OutlineButton
                                    style={{ marginRight: '0px' }}
                                    isSelected={
                                        !isEmailingIcpOnly &&
                                        (!emailDistributionConfig ||
                                            emailDistributionConfig === EmailDistributionConfig.All)
                                    }
                                    isDisabled={false}
                                    onClick={() => {
                                        if (!user.hasProFeatures) {
                                            return;
                                        }
                                        setIsEmailingIcpOnly(false);
                                        setEmailDistributionConfig(EmailDistributionConfig.All);
                                        track(ClientEventType.StepTrackerAddToMeetingsSummarySendOptionClicked, {
                                            SummarySendOption: 'Everyone on the invite',
                                            IsAgentOnboarding: isAgentOnboarding,
                                        });
                                    }}
                                >
                                    Everyone on the invite
                                </OutlineButton>
                                <OutlineButton
                                    style={{ marginRight: '0px' }}
                                    isSelected={isEmailingIcpOnly}
                                    isDisabled={!user.hasProFeatures}
                                    onClick={() => {
                                        if (!user.hasProFeatures) {
                                            return;
                                        }
                                        setIsEmailingIcpOnly(true);
                                        track(ClientEventType.StepTrackerAddToMeetingsSummarySendOptionClicked, {
                                            SummarySendOption: 'Just you',
                                            IsAgentOnboarding: isAgentOnboarding,
                                        });
                                    }}
                                >
                                    Just you
                                </OutlineButton>
                                {user.isEnabledForInternalOnlyEmailDistribution ? (
                                    <OutlineButton
                                        style={{ marginRight: '0px' }}
                                        isSelected={
                                            !isEmailingIcpOnly &&
                                            emailDistributionConfig === EmailDistributionConfig.InternalOnly
                                        }
                                        isDisabled={!user.hasProFeatures}
                                        onClick={() => {
                                            if (!user.hasProFeatures) {
                                                return;
                                            }
                                            setIsEmailingIcpOnly(false);
                                            setEmailDistributionConfig(EmailDistributionConfig.InternalOnly);
                                            track(ClientEventType.StepTrackerAddToMeetingsSummarySendOptionClicked, {
                                                SummarySendOption: 'Invitees from your company',
                                                IsAgentOnboarding: isAgentOnboarding,
                                            });
                                        }}
                                    >
                                        Invitees from your company
                                    </OutlineButton>
                                ) : null}
                            </Row>
                        </Column>
                        <Column style={{ flex: 0 }}>
                            <ProBadge />
                        </Column>
                    </Row>
                    <Spacing factor={1} />
                    <Divider />
                    <Spacing factor={1} />
                    <Row vCenter>
                        <SpinachSwitch
                            checked={editSummariesBeforeTheyAreSent}
                            disabled={!user.hasProFeatures}
                            onChange={async () => {
                                setEditSummariesBeforeTheyAreSent(!editSummariesBeforeTheyAreSent);
                                track(ClientEventType.StepTrackerAddToMeetingsEditSummariesSwitchToggled, {
                                    IsEditSummariesEnabled: !editSummariesBeforeTheyAreSent,
                                    IsAgentOnboarding: isAgentOnboarding,
                                });
                            }}
                        />
                        <BodyRegularOnboard style={{ marginLeft: '10px' }}>
                            Let me edit summaries before sending to meeting participants
                        </BodyRegularOnboard>
                        <ProBadge />
                    </Row>
                    <Spacing factor={1} />

                    <Row centered>
                        <PrimaryButton
                            style={{ zIndex: 1000 }}
                            title={'Continue'}
                            onClick={onContinue}
                            loadingText={loadingMessage}
                            isLoading={!!loadingMessage}
                        />
                    </Row>
                </InnerContentContainer>
                <Spacing factor={1} />
            </ContentContainer>
        </Container>
    );
}
