import { useGlobalAuthedUser } from '../../../../../hooks';
import { BodyRegularOnboard, HeaderThree } from '../../../../../styles';
import { Row, Spacing } from '../../../../common';
import { IntegrationSettingsModalKind } from '../../types';
import { useHoverRow } from '../SettingsSection';
import {
    AsanaIntegrationSection,
    JiraIntegrationSection,
    TrelloIntegrationSection,
} from '../TicketingIntegrationSection';

export function SpinachAppTicketingIntegrationsSection({
    setModalKind,
}: {
    setModalKind: (modalKind: IntegrationSettingsModalKind) => void;
}): JSX.Element {
    const [user] = useGlobalAuthedUser();
    const hoverRowProps = useHoverRow();

    return (
        <>
            <Row>
                <HeaderThree>Ticketing</HeaderThree>
            </Row>
            <Row>
                <BodyRegularOnboard>Search, reference, and link tickets</BodyRegularOnboard>
            </Row>
            <Spacing factor={1 / 2} />
            <JiraIntegrationSection
                showProjectSelectionModal={false}
                setModalKind={setModalKind}
                hoverRowProps={hoverRowProps}
            />
            <Spacing factor={1 / 2} />
            {user.isAsanaEnabled ? (
                <AsanaIntegrationSection
                    showProjectSelectionModal={false}
                    setModalKind={setModalKind}
                    hoverRowProps={hoverRowProps}
                />
            ) : (
                <></>
            )}
            <Spacing factor={1 / 2} />
            <TrelloIntegrationSection isComingSoon={true} setModalKind={setModalKind} hoverRowProps={hoverRowProps} />
        </>
    );
}
