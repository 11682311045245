import styled from 'styled-components';

import { useGlobalAuthedUser } from '../../../../..';
import OnboardingAgent from '../../../../assets/onboarding-agent.png';
import { OnboardingStep } from '../common';

export type AgentGuideProps = {
    onboardingStep: OnboardingStep;
};
const AgentContainer = styled.div`
    pointer-events: none;
    z-index: 1000;
    position: fixed;
    bottom: 0;
    box-sizing: border-box;
    height: 300px;
    width: 300px;
    margin: auto;
    left: 0;
    right: 0;
    display: none;
    @media (min-width: 920px) {
        display: initial;
        height: 27vw;
        width: 22.6vw;
        left: 25px;
        right: unset;
    }
    @media (min-width: 1150px) {
        height: 400px;
        width: 335px;
    }
    @media (min-width: 1250px) {
        height: 449px;
        width: 377px;
    }
`;

const AgentImage = styled.img`
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
`;

const CaptionContainer = styled.div`
    position: absolute;
    bottom: 30px;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
`;

const Caption = styled.div`
    width: 269px;
    color: white;
    font-family: Gilroy;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%; /* 21.6px */
    background: black;
    padding: 5px;
    border-radius: 4px;
`;

const InnerContainer = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
`;

export function AgentGuide({ onboardingStep }: AgentGuideProps): JSX.Element {
    let caption = undefined;
    switch (onboardingStep) {
        case OnboardingStep.About:
            caption = "Hey there, I'm Spinach AI. I'll be ready to go after a few quick setup steps.";
            break;
        case OnboardingStep.CalendarPermissions:
            caption = 'Connect your calendar so we can coordinate where I should take notes';

            break;
        case OnboardingStep.AddSpinachToMeetings:
            caption = 'Select which meetings I should join to make your life a little easier.';
            break;
    }
    return (
        <AgentContainer>
            <InnerContainer>
                <AgentImage src={OnboardingAgent}></AgentImage>
                {caption ? (
                    <CaptionContainer>
                        <Caption>{caption}</Caption>
                    </CaptionContainer>
                ) : null}
            </InnerContainer>
        </AgentContainer>
    );
}
