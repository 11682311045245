import { SpinachIntegration } from '@spinach-shared/types';

import { useDisabledKnowledgeBaseForFreeUsers, useProFeatures } from '../../../../../hooks';
import { BodyRegularOnboard, HeaderThree } from '../../../../../styles';
import { ProBadge, Row, Spacing } from '../../../../common';
import { IntegrationSettingsModalKind } from '../../types';
import { IntegrationProps, IntegrationSection } from '../IntegrationSection';
import { GoogleDriveIntegrationSection } from '../KnowledgeBaseIntegrationSection';
import { useHoverRow } from '../SettingsSection';

export function SpinachAppKnowledgeBaseIntegrationSection({
    setModalKind,
}: {
    setModalKind: (modalKind: IntegrationSettingsModalKind) => void;
}): JSX.Element {
    const hoverRowProps = useHoverRow();
    const hasProFeatures = useProFeatures();
    const disabledKnowledgeBaseForFreeUsers = useDisabledKnowledgeBaseForFreeUsers();
    const locked = disabledKnowledgeBaseForFreeUsers && !hasProFeatures;

    const integrations: IntegrationProps[] = [
        {
            integration: SpinachIntegration.Confluence,
        },
    ];

    return (
        <>
            <Row>
                <HeaderThree>Knowledge Base</HeaderThree>
                {disabledKnowledgeBaseForFreeUsers ? <ProBadge /> : <></>}
            </Row>
            <Spacing factor={1 / 2} />
            <Row>
                <BodyRegularOnboard>Keep your summaries in a spot</BodyRegularOnboard>
            </Row>
            <Spacing factor={1 / 2} />
            {integrations.map((integration) => (
                <>
                    <IntegrationSection
                        key={integration.integration}
                        integration={integration.integration}
                        locked={locked}
                        isComingSoon={true}
                        setModalKind={setModalKind}
                        hoverRowProps={hoverRowProps}
                    />
                </>
            ))}
            <GoogleDriveIntegrationSection setModalKind={setModalKind} hoverRowProps={hoverRowProps} locked={locked} />
        </>
    );
}
