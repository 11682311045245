import { useEffect, useState } from 'react';

import { EmailDistributionConfig } from '@spinach-shared/types';
import { StoredSpinachSeries } from '@spinach-shared/utils';

import { useEmailSummaryToInviterOnly, useGlobalAuthedUser } from '../../../hooks';
import { DropDown } from '../../common';

export function EmailOptions({
    isEmailingIcpOnlySetting,
    onSelect,
    series,
}: {
    isEmailingIcpOnlySetting: boolean | undefined;
    onSelect: (isEmailingIcpOnly: boolean, emailDistributionConfig: EmailDistributionConfig) => Promise<void>;
    series?: StoredSpinachSeries;
}): JSX.Element {
    const [user] = useGlobalAuthedUser();
    const isEmailInviterSummaryInviterOnly = useEmailSummaryToInviterOnly();

    const ALL_PARTICIPANTS_OPTION = { code: 'all-invitees', label: 'Everyone on the invite' };
    const INTERNAL_ONLY_OPTION = { code: 'internal-only', label: 'Invitees from your company' };

    const inviterLabel =
        series?.createdBy === user.spinachUserId
            ? 'Just me'
            : `Just the Spinach Host ${series?.icpEmail ? `(${series.icpEmail})` : ''}`;
    const INVITER_OPTION = { code: 'inviter-only', label: inviterLabel };
    let dropDownValues = [ALL_PARTICIPANTS_OPTION, INVITER_OPTION];
    if (user.isEnabledForActiveMeetingTypeClassification) {
        dropDownValues.push(INTERNAL_ONLY_OPTION);
    }

    const emailDistributionConfig =
        series?.scribeMetadata?.emailDistributionConfig ?? user.metadata?.emailDistributionConfig;

    let isEmailInviterOnly = isEmailingIcpOnlySetting;
    if (isEmailInviterSummaryInviterOnly) {
        isEmailInviterOnly = true;
        dropDownValues = [INVITER_OPTION];
    }

    const [emailSelection, setEmailSelection] = useState(
        isEmailInviterOnly
            ? INVITER_OPTION
            : emailDistributionConfig === EmailDistributionConfig.InternalOnly
            ? INTERNAL_ONLY_OPTION
            : ALL_PARTICIPANTS_OPTION
    );

    useEffect(() => {
        setEmailSelection(
            isEmailInviterOnly
                ? INVITER_OPTION
                : emailDistributionConfig === EmailDistributionConfig.InternalOnly
                ? INTERNAL_ONLY_OPTION
                : ALL_PARTICIPANTS_OPTION
        );
    }, [isEmailInviterOnly, emailDistributionConfig]);

    return (
        <DropDown
            title={''}
            values={dropDownValues}
            buttonProps={{ style: { transform: 'scale(0.8)', transformOrigin: 'center left', padding: '4px 24px' } }}
            handleSelection={async (code) => {
                if (code === emailSelection.code) {
                    // no change
                    return;
                }

                let isEmailingIcpOnly;
                let emailDistributionConfig;

                if (code === ALL_PARTICIPANTS_OPTION.code) {
                    setEmailSelection(ALL_PARTICIPANTS_OPTION);
                    isEmailingIcpOnly = false;
                    emailDistributionConfig = EmailDistributionConfig.All;
                } else if (code === INTERNAL_ONLY_OPTION.code) {
                    setEmailSelection(INTERNAL_ONLY_OPTION);
                    isEmailingIcpOnly = false;
                    emailDistributionConfig = EmailDistributionConfig.InternalOnly;
                } else {
                    setEmailSelection(INVITER_OPTION);
                    isEmailingIcpOnly = true;
                    emailDistributionConfig = EmailDistributionConfig.All;
                }

                await onSelect(isEmailingIcpOnly, emailDistributionConfig);
            }}
            selected={emailSelection.label}
        />
    );
}
