import { Box, Modal } from '@material-ui/core';
import React, { useState } from 'react';

import {
    ClientEventType,
    IClientUser,
    KnowledgeBaseIntegration,
    SpinachAPIPath,
    SpinachIntegration,
    TicketIntegration,
} from '@spinach-shared/types';

import { deleteIntegration, deleteUserSlackSettings, getUser, postSetupKnowledgeBaseSummary } from '../../../apis';
import { deleteGoogleCalendarSettings } from '../../../apis/deleteGoogleCalendarSettings';
import { deleteSpinachAPI } from '../../../apis/deleteSpinachAPI';
import { disconnectMicrosoftCalendar } from '../../../apis/disconnectMicrosoftCalendar';
import { GlobalModal, GlobalModalMetadataType } from '../../../atoms';
import {
    useExperienceTracking,
    useGlobalAuthedUser,
    useGlobalModal,
    useGlobalNullableStoredSeries,
    useIntegrationDetection,
} from '../../../hooks';
import { useTicketProjects } from '../../../hooks/useTicketProjects';
import { BodyRegularOnboard, Hyperlink } from '../../../styles';
import { SetValue } from '../../../types';
import { URLUtil } from '../../../utils';
import { CloseButton, Column, Row, Spacing, SpinachModalContent } from '../../common';
import { AtlassianSiteSelectionContent } from '../../common/modals/AtlassianSiteSelectionContent';
import { AtlassianTopLevelWarning } from '../../common/modals/AtlassianTopLevelWarning';
import { ConfluenceSiteSelectionContent } from '../../common/modals/ConfluenceSiteSelectionContent';
import { ConfluenceSpaceSelectionContent } from '../../common/modals/ConfluenceSpaceSelectionContent';
import { DriveVideoUploadSettings } from '../../common/modals/DriveVideoUploadSettings';
import { SelectTicketProjectContent } from '../../common/modals/SelectTicketProject';
import { NotionIntegrationUpgradeMessage } from '../../common/settings/NotionIntegrationSettings';
import { PrimaryButton } from '../../stand-up';
import { OutlinedButton } from '../../stand-up/OutlinedButton';
import { JiraUpgradeWarning } from './JiraUpgradeWarning';
import { IntegrationSettingsModalKind, IntegrationSettingsModalProps } from './types';

export const MODAL_KIND_TO_SPINACH_INTEGRATION_MAPPING: Record<IntegrationSettingsModalKind, SpinachIntegration> = {
    [IntegrationSettingsModalKind.SettingsSalesforce]: SpinachIntegration.Salesforce,
    [IntegrationSettingsModalKind.SettingsAttio]: SpinachIntegration.Attio,
    [IntegrationSettingsModalKind.SettingsZoho]: SpinachIntegration.Zoho,
    [IntegrationSettingsModalKind.SettingsGoogleDrive]: SpinachIntegration.GoogleDrive,
    [IntegrationSettingsModalKind.SettingsSlack]: SpinachIntegration.Slack,
    [IntegrationSettingsModalKind.SettingsGoogleCalendar]: SpinachIntegration.GoogleCalendar,
    [IntegrationSettingsModalKind.SettingsMicrosoftCalendar]: SpinachIntegration.Microsoft,
    [IntegrationSettingsModalKind.SettingsJira]: SpinachIntegration.Jira,
    [IntegrationSettingsModalKind.SettingsZoom]: SpinachIntegration.Zoom,
    [IntegrationSettingsModalKind.SettingsAsana]: SpinachIntegration.Asana,
    [IntegrationSettingsModalKind.SettingsLinear]: SpinachIntegration.Linear,
    [IntegrationSettingsModalKind.SettingsClickUp]: SpinachIntegration.Clickup,
    [IntegrationSettingsModalKind.SettingsTrello]: SpinachIntegration.Trello,
    [IntegrationSettingsModalKind.SettingsMondayDotCom]: SpinachIntegration.MondayDotCom,
    [IntegrationSettingsModalKind.SettingsConfluence]: SpinachIntegration.Confluence,
    [IntegrationSettingsModalKind.SettingsNotion]: SpinachIntegration.Notion,
    [IntegrationSettingsModalKind.SettingsHubSpot]: SpinachIntegration.HubSpot,
};

const IntegrationSettingsModalContent = ({
    modalKind,
    setModalKind,
    modalMetadata,
    onNext,
    pendingDisconnectConfirmation,
    setPendingDisconnectConfirmation,
    isLoadingDisconnect,
    setIsLoadingDisconnect,
}: IntegrationSettingsModalProps & {
    modalKind: IntegrationSettingsModalKind;
    pendingDisconnectConfirmation: boolean;
    onNext: (step?: IntegrationSettingsModalStep) => void;
    setPendingDisconnectConfirmation: SetValue<boolean>;
    isLoadingDisconnect: boolean;
    setIsLoadingDisconnect: SetValue<boolean>;
}) => {
    const [user] = useGlobalAuthedUser();
    const [, setStoredSeries] = useGlobalNullableStoredSeries();
    const track = useExperienceTracking();
    const startDetection = useIntegrationDetection(() => {
        setIsLoadingDisconnect(false);
        setPendingDisconnectConfirmation(false);
        setModalKind(null);
        setStoredSeries(undefined);
    });
    useTicketProjects();
    async function proccessDisconnect(disconnect: () => Promise<IClientUser | null | undefined | void>) {
        setIsLoadingDisconnect(true);

        track(ClientEventType.AIDashboardClick, { ClickedOn: `Confirm ${modalKind} Modal` });

        // make sure it is not too fast for the user to see the loading state
        await Promise.all([new Promise((resolve) => setTimeout(resolve, 1000)), disconnect()]);

        startDetection();
    }

    const disconnectMapping: Record<
        IntegrationSettingsModalKind,
        { name: string; disconnect: () => Promise<any>; ticketSource?: TicketIntegration }
    > = {
        [IntegrationSettingsModalKind.SettingsSalesforce]: {
            name: 'Salesforce',
            disconnect: () => deleteIntegration(SpinachIntegration.Salesforce),
        },
        [IntegrationSettingsModalKind.SettingsAttio]: {
            name: 'Attio',
            disconnect: () => deleteIntegration(SpinachIntegration.Attio),
        },
        [IntegrationSettingsModalKind.SettingsZoho]: {
            name: 'Zoho',
            disconnect: () => deleteIntegration(SpinachIntegration.Zoho),
        },
        [IntegrationSettingsModalKind.SettingsHubSpot]: {
            name: 'HubSpot',
            disconnect: () => deleteIntegration(SpinachIntegration.HubSpot),
        },
        [IntegrationSettingsModalKind.SettingsGoogleDrive]: {
            name: 'Google Drive',
            disconnect: () => deleteSpinachAPI(SpinachAPIPath.GoogleDriveDisconnect),
        },
        [IntegrationSettingsModalKind.SettingsSlack]: {
            name: 'Slack',
            disconnect: deleteUserSlackSettings,
        },
        [IntegrationSettingsModalKind.SettingsGoogleCalendar]: {
            name: 'Google Calendar',
            disconnect: deleteGoogleCalendarSettings,
        },
        [IntegrationSettingsModalKind.SettingsMicrosoftCalendar]: {
            name: 'Microsoft Calendar',
            disconnect: disconnectMicrosoftCalendar,
        },
        [IntegrationSettingsModalKind.SettingsJira]: {
            name: 'Jira',
            disconnect: () => deleteSpinachAPI(SpinachAPIPath.JiraDisconnect),
            ticketSource: SpinachIntegration.Jira,
        },
        [IntegrationSettingsModalKind.SettingsZoom]: {
            name: 'Zoom',
            disconnect: () => deleteSpinachAPI(SpinachAPIPath.RecallZoomApp),
        },
        [IntegrationSettingsModalKind.SettingsAsana]: {
            name: 'Asana',
            disconnect: () => deleteSpinachAPI(SpinachAPIPath.AsanaDisconnect),
            ticketSource: SpinachIntegration.Asana,
        },
        [IntegrationSettingsModalKind.SettingsLinear]: {
            name: 'Linear',
            disconnect: () => deleteSpinachAPI(SpinachAPIPath.LinearDisconnect),
            ticketSource: SpinachIntegration.Linear,
        },
        [IntegrationSettingsModalKind.SettingsClickUp]: {
            name: 'ClickUp',
            disconnect: () => deleteSpinachAPI(SpinachAPIPath.ClickUpDisconnect),
            ticketSource: SpinachIntegration.Clickup,
        },
        [IntegrationSettingsModalKind.SettingsTrello]: {
            name: 'Trello',
            disconnect: () => deleteIntegration(SpinachIntegration.Trello),
            ticketSource: SpinachIntegration.Trello,
        },
        [IntegrationSettingsModalKind.SettingsMondayDotCom]: {
            name: 'Monday.com',
            disconnect: () => deleteIntegration(SpinachIntegration.MondayDotCom),
            ticketSource: SpinachIntegration.MondayDotCom,
        },
        [IntegrationSettingsModalKind.SettingsConfluence]: {
            name: 'Confluence',
            disconnect: () => deleteIntegration(SpinachIntegration.Confluence),
        },
        [IntegrationSettingsModalKind.SettingsNotion]: {
            name: 'Notion',
            disconnect: () => deleteIntegration(SpinachIntegration.Notion),
        },
    };
    const { name, disconnect, ticketSource } = disconnectMapping[modalKind];

    const showOutdatedJiraWarning =
        modalKind === IntegrationSettingsModalKind.SettingsJira &&
        user.isAuthedForJira &&
        !user.integrationSettings?.jiraSettings?.hasCreateScopes;

    const iOutdatedNotionWarning =
        modalKind === IntegrationSettingsModalKind.SettingsNotion &&
        user.integrationSettings?.notionSettings?.isAuthed &&
        user.integrationSettings.notionSettings.notionSummarySetupFailed === undefined;

    const isAtlassianProduct =
        modalKind === IntegrationSettingsModalKind.SettingsJira ||
        modalKind === IntegrationSettingsModalKind.SettingsConfluence;

    const isConfluenceSettings = modalKind === IntegrationSettingsModalKind.SettingsConfluence;
    const isDriveSettings = modalKind === IntegrationSettingsModalKind.SettingsGoogleDrive;

    return (
        <Column>
            <Box style={{ textAlign: 'center', fontWeight: 300, fontSize: '30px' }}>{name}</Box>

            <Box
                style={{
                    marginTop: '15px',
                    marginBottom: '20px',
                    background: 'black',
                    height: '1px',
                    opacity: 0.1,
                    alignSelf: 'stretch',
                }}
            />

            {showOutdatedJiraWarning ? <JiraUpgradeWarning /> : undefined}
            {iOutdatedNotionWarning ? (
                <Box mb="25px">
                    <NotionIntegrationUpgradeMessage />
                </Box>
            ) : undefined}

            {isAtlassianProduct ? (
                <>
                    <AtlassianTopLevelWarning
                        atlassianSource={
                            MODAL_KIND_TO_SPINACH_INTEGRATION_MAPPING[modalKind] as
                                | SpinachIntegration.Jira
                                | SpinachIntegration.Confluence
                        }
                        isFirstTimeAuth={Boolean(
                            modalMetadata?.[
                                modalKind as
                                    | IntegrationSettingsModalKind.SettingsJira
                                    | IntegrationSettingsModalKind.SettingsConfluence
                            ]?.isFirstTimeAuth
                        )}
                    />
                    {isConfluenceSettings && user.isPasteSiteSelectionEnabled ? (
                        <ConfluenceSiteSelectionContent />
                    ) : (
                        <AtlassianSiteSelectionContent
                            isFirstTimeAuth={Boolean(
                                modalMetadata?.[
                                    modalKind as
                                        | IntegrationSettingsModalKind.SettingsJira
                                        | IntegrationSettingsModalKind.SettingsConfluence
                                ]?.isFirstTimeAuth
                            )}
                            atlassianSource={
                                MODAL_KIND_TO_SPINACH_INTEGRATION_MAPPING[modalKind] as
                                    | SpinachIntegration.Jira
                                    | SpinachIntegration.Confluence
                            }
                        />
                    )}
                    <Spacing factor={1 / 3} />
                </>
            ) : (
                <></>
            )}
            {isConfluenceSettings && !user.isPasteSiteSelectionEnabled ? <ConfluenceSpaceSelectionContent /> : <></>}
            {isDriveSettings ? (
                <>
                    <Box display="flex" flexDirection="column">
                        <Box
                            style={{
                                fontWeight: 500,
                                fontSize: '17px',
                                textTransform: 'uppercase',
                                letterSpacing: '1.5px',
                            }}
                        >{`Meeting Notes`}</Box>
                        <Box
                            style={{ fontSize: '15px', marginTop: '5px', marginBottom: '10px' }}
                        >{`Meeting notes are automatically saved to your Google Drive. You can move the folder and Spinach will still write to the new location.`}</Box>
                    </Box>
                    <Box
                        style={{
                            marginTop: '15px',
                            marginBottom: '20px',
                            background: 'black',
                            height: '1px',
                            opacity: 0.1,
                            alignSelf: 'stretch',
                        }}
                    />
                    <DriveVideoUploadSettings />
                </>
            ) : (
                <></>
            )}

            {ticketSource ? (
                <>
                    <SelectTicketProjectContent ticketSource={ticketSource} />
                    <Box
                        style={{
                            marginTop: '15px',
                            marginBottom: '20px',
                            background: 'black',
                            height: '1px',
                            opacity: 0.1,
                            alignSelf: 'stretch',
                        }}
                    />
                </>
            ) : undefined}

            <Box display="flex" flexDirection="column">
                <Box
                    style={{
                        fontWeight: 500,
                        fontSize: '17px',
                        textTransform: 'uppercase',
                        letterSpacing: '1.5px',
                    }}
                >{`Disconnect ${name}`}</Box>
                <Box
                    style={{ fontSize: '15px', marginTop: '5px' }}
                >{`After disconecting, you will no longer be integrated with ${name}`}</Box>
                <OutlinedButton
                    style={{ flexShrink: 1, alignSelf: 'flex-start', marginTop: '10px' }}
                    title={`Remove ${name} Connection`}
                    disabled={isLoadingDisconnect || pendingDisconnectConfirmation}
                    onClick={() => setPendingDisconnectConfirmation(true)}
                />
                {isAtlassianProduct && !(pendingDisconnectConfirmation || isLoadingDisconnect) ? (
                    <>
                        <Spacing factor={1 / 3} />
                        <PrimaryButton
                            style={{ flexShrink: 1, alignSelf: 'center', marginTop: '10px' }}
                            title={isConfluenceSettings && user.isPasteSiteSelectionEnabled ? 'Continue' : 'Done'}
                            loadingText={''}
                            onClick={() => onNext()}
                        />
                    </>
                ) : (
                    <></>
                )}
                {pendingDisconnectConfirmation || isLoadingDisconnect ? (
                    <Box display="flex" flex="row" mt="10px">
                        <PrimaryButton
                            style={{ flex: 40 }}
                            isLoading={isLoadingDisconnect}
                            title={'Yes, Remove'}
                            loadingText={''}
                            onClick={() => proccessDisconnect(disconnect)}
                        />
                        <Box width="10px" />
                        <OutlinedButton
                            labelStyles={{ textAlign: 'center', display: 'flex', justifyContent: 'center' }}
                            style={{ flex: 60 }}
                            disabled={isLoadingDisconnect}
                            title={`Cancel`}
                            onClick={() => setPendingDisconnectConfirmation(false)}
                        />
                    </Box>
                ) : undefined}
            </Box>
        </Column>
    );
};

export enum IntegrationSettingsModalStep {
    ConfluenceSiteAndSpaceConfirmationStep = 'ConfluenceSiteAndSpaceConfirmationStep',
}

const ConfluenceSiteAndSpaceConfirmationStep = ({
    onNext,
}: {
    onNext: (step?: IntegrationSettingsModalStep) => void;
}) => {
    const [user] = useGlobalAuthedUser();
    const confluenceAccountUrl = user.integrationSettings?.confluenceSettings?.confluenceAccountUrl;
    const defaultSpaceKey = user.integrationSettings?.confluenceSettings?.defaultSpaceKey;
    const pageUrl = `${confluenceAccountUrl}/wiki/spaces/${defaultSpaceKey}`;
    return (
        <Column>
            <Box style={{ textAlign: 'center', fontWeight: 300, fontSize: '30px' }}>You're all set!</Box>

            <Box
                style={{
                    marginTop: '15px',
                    marginBottom: '20px',
                    background: 'black',
                    height: '1px',
                    opacity: 0.1,
                    alignSelf: 'stretch',
                }}
            />
            <BodyRegularOnboard>Spinach will send meeting notes here:</BodyRegularOnboard>

            <Spacing factor={1 / 3} />
            <Row style={{ backgroundColor: '#F4F6F6', padding: '8px 12px 8px 12px' }}>
                <Hyperlink onClick={() => URLUtil.openURL(pageUrl)}>{pageUrl}</Hyperlink>
            </Row>

            <Spacing factor={1 / 3} />
            <BodyRegularOnboard>
                Note: You can change the folder location (in the space) and permissions in Confluence
            </BodyRegularOnboard>

            <div style={{ flexGrow: 1 }} />
            <Spacing />
            <PrimaryButton
                title={'Done'}
                onClick={() => onNext(IntegrationSettingsModalStep.ConfluenceSiteAndSpaceConfirmationStep)}
            />
        </Column>
    );
};

const stepMapping: Record<IntegrationSettingsModalStep, (props: { onNext: () => void }) => JSX.Element> = {
    [IntegrationSettingsModalStep.ConfluenceSiteAndSpaceConfirmationStep]: (props) => (
        <ConfluenceSiteAndSpaceConfirmationStep {...props} />
    ),
};

export function IntegrationSettingsModal({
    modalKind,
    setModalKind,
    modalMetadata,
    setModalMetadata,
}: IntegrationSettingsModalProps): JSX.Element {
    const isOpen = !!modalKind;
    const [user, setUser] = useGlobalAuthedUser();
    const [, setGlobalModal] = useGlobalModal();

    const [isLoadingDisconnect, setIsLoadingDisconnect] = useState(false);
    const [pendingDisconnectConfirmation, setPendingDisconnectConfirmation] = useState(false);
    const track = useExperienceTracking();
    const [, setStoredSeries] = useGlobalNullableStoredSeries();
    const [integrationSettingsModalStep, setIntegrationSettingsModalStep] = useState<
        IntegrationSettingsModalStep | undefined
    >();

    function onClose() {
        setIntegrationSettingsModalStep(undefined);
        track(ClientEventType.AIDashboardClick, { ClickedOn: `Close ${modalKind} Modal` });
        setModalKind(null);
        setStoredSeries(undefined);
        setPendingDisconnectConfirmation(false);

        if (
            modalKind === IntegrationSettingsModalKind.SettingsConfluence &&
            modalMetadata?.[IntegrationSettingsModalKind.SettingsConfluence]?.isFirstTimeAuth
        ) {
            const setup = async (knowledgeBaseIntegration: KnowledgeBaseIntegration) => {
                const response = await postSetupKnowledgeBaseSummary({ knowledgeBaseIntegration });
                const userResponse = await getUser();
                if (userResponse.user) {
                    setUser(userResponse.user);
                }

                setGlobalModal(GlobalModal.KnowledgeBaseSetupComplete, {
                    metadata: {
                        metadataType: GlobalModalMetadataType.KnowledgeBaseSetup,
                        knowledgeBaseIntegration,
                        setupFailed: !response || !!response?.setupFailed,
                    },
                });
            };
            setup(SpinachIntegration.Confluence);
        }
        if (setModalMetadata) {
            setModalMetadata({
                ...modalMetadata,
                [IntegrationSettingsModalKind.SettingsConfluence]: {
                    ...modalMetadata?.[IntegrationSettingsModalKind.SettingsConfluence],
                    isFirstTimeAuth: false,
                },
                [IntegrationSettingsModalKind.SettingsJira]: {
                    ...modalMetadata?.[IntegrationSettingsModalKind.SettingsJira],
                    isFirstTimeAuth: false,
                },
            });
        }
    }

    const isConfluenceSettings = modalKind === IntegrationSettingsModalKind.SettingsConfluence;
    if (!isOpen) {
        return <></>;
    }

    const onNext = (step?: IntegrationSettingsModalStep) => {
        if (
            step !== IntegrationSettingsModalStep.ConfluenceSiteAndSpaceConfirmationStep &&
            isConfluenceSettings &&
            user.isPasteSiteSelectionEnabled
        ) {
            setIntegrationSettingsModalStep(IntegrationSettingsModalStep.ConfluenceSiteAndSpaceConfirmationStep);
        } else {
            onClose();
        }
    };

    return (
        <Modal open={isOpen} onClose={onClose}>
            <SpinachModalContent
                onClose={onClose}
                style={{
                    minWidth: '310px',
                    padding: '30px',
                    minHeight: '270px',
                    overflow: 'initial',
                }}
            >
                <CloseButton
                    style={{
                        left: '3px',
                        top: '9px',
                    }}
                    onClick={onClose}
                />
                {integrationSettingsModalStep ? (
                    stepMapping[integrationSettingsModalStep]({ onNext })
                ) : (
                    <IntegrationSettingsModalContent
                        modalMetadata={modalMetadata}
                        setModalMetadata={setModalMetadata}
                        modalKind={modalKind}
                        setModalKind={setModalKind}
                        onNext={isConfluenceSettings && user.isPasteSiteSelectionEnabled ? onNext : onClose}
                        pendingDisconnectConfirmation={pendingDisconnectConfirmation}
                        setPendingDisconnectConfirmation={setPendingDisconnectConfirmation}
                        isLoadingDisconnect={isLoadingDisconnect}
                        setIsLoadingDisconnect={setIsLoadingDisconnect}
                    />
                )}
            </SpinachModalContent>
        </Modal>
    );
}
