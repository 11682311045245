import React, { useState } from 'react';

import { SpinachIntegration } from '@spinach-shared/types';

import AttioLogo from '../../../../assets/integrations/attio.png';
import HubSpotExample from '../../../../assets/integrations/hubspot-example.png';
import { ReactComponent as HubspotLogo } from '../../../../assets/integrations/hubspot.svg';
import SalesforceLogo from '../../../../assets/integrations/salesforce.png';
import ZohoLogo from '../../../../assets/integrations/zoho.png';
import { BodyRegularOnboard, HeaderThree, lightTheme } from '../../../../styles';
import { BetaFeatureTag, Row, Spacing } from '../../../common';
import { IntegrationSettingsModalKind, UseHoverRowProps } from '../types';
import { IntegrationProps, IntegrationSection } from './IntegrationSection';

export function CRMIntegrationsSection({
    setModalKind,
    hoverRowProps,
}: {
    setModalKind: (modalKind: IntegrationSettingsModalKind) => void;
    hoverRowProps: UseHoverRowProps;
}): JSX.Element {
    const [isLearnMoreHovered, setIsLearnMoreHovered] = useState(false);

    const integrations: IntegrationProps[] = [
        {
            integration: SpinachIntegration.HubSpot,
            customLogo: <HubspotLogo style={{ width: '24px', height: '24px', marginRight: '15px' }} />,
            isNew: true,
            customTooltip: (
                <>
                    <Row style={{ width: 'unset' }} centered>
                        <img
                            src={HubSpotExample}
                            style={{
                                maxWidth: '350px',
                                maxHeight: '350px',
                                flexShrink: 1,
                            }}
                        />
                    </Row>
                    <Spacing factor={1 / 3} />
                    <BodyRegularOnboard style={{ textAlign: 'center' }}>
                        Automatically sync meeting notes to contact in HubSpot after the call
                    </BodyRegularOnboard>
                </>
            ),
        },
        {
            integration: SpinachIntegration.Salesforce,
            customLogo: SalesforceLogo,
        },
        {
            integration: SpinachIntegration.Attio,
            customLogo: AttioLogo,
        },
        {
            integration: SpinachIntegration.Zoho,
            customLogo: ZohoLogo,
        },
    ];

    return (
        <>
            <Row vCenter>
                <HeaderThree>CRM Integrations</HeaderThree>
                <BetaFeatureTag />
            </Row>
            <Spacing factor={1 / 2} />
            <Row>
                <BodyRegularOnboard>
                    Sync your meeting data with your CRM (
                    <span
                        style={{
                            textDecoration: 'underline',
                            cursor: 'pointer',
                            color: isLearnMoreHovered ? '#0068b1' : lightTheme.primary.midnight,
                        }}
                        onMouseEnter={() => setIsLearnMoreHovered(true)}
                        onMouseLeave={() => setIsLearnMoreHovered(false)}
                        onClick={() => window.Intercom?.('showArticle', '9961431')}
                    >
                        learn more
                    </span>
                    )
                </BodyRegularOnboard>
            </Row>
            <Spacing factor={1 / 2} />
            {integrations.map((integration) => (
                <>
                    <IntegrationSection
                        key={integration.integration}
                        integration={integration.integration}
                        setModalKind={setModalKind}
                        hoverRowProps={hoverRowProps}
                        isNew={integration.isNew}
                        isComingSoon={integration.isComingSoon}
                        locked={integration.locked}
                        onConnect={integration.onConnect}
                        customLogo={integration.customLogo}
                        customTooltip={integration.customTooltip}
                    />
                    <Spacing factor={1 / 2} />
                </>
            ))}
        </>
    );
}
