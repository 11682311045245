import { v4 } from 'uuid';

import {
    ClientVideoAgentSessionJSON,
    MeetingActionItem,
    MeetingBlocker,
    NewVideoAgentSessionJSON,
    Primitive,
    RecallChatEventData,
    Trigger,
    VideoAgentAgenda,
    VideoAgentChatMessage,
    VideoAgentParticipant,
    VideoAgentPreviousContext,
    VideoAgentSessionPhase,
    VideoAgentSessionTopic,
    VideoAgentSessionTopicKind,
    VideoAgentSettings,
} from '@spinach-shared/types';

import { FacilitatedStartStops } from '../time';

export class VideoAgentSession {
    raw: ClientVideoAgentSessionJSON;

    constructor(json: ClientVideoAgentSessionJSON) {
        this.raw = json;
        // TODO = instantiate start-stops for all topics
    }

    get scheduledEndTime(): Date {
        return new Date(this.raw.scheduledEndTime);
    }

    get scheduledStartTime(): Date {
        return new Date(this.raw.scheduledStartTime);
    }

    get botId(): string {
        return this.raw.botId;
    }

    get triggers(): Trigger[] {
        return this.raw.triggers;
    }

    get chatMessages(): VideoAgentChatMessage[] {
        return this.raw.chatMessages;
    }

    get settings(): VideoAgentSettings {
        return this.raw.settings;
    }

    get agenda(): VideoAgentAgenda {
        return this.raw.agenda;
    }

    get hostId(): string {
        return this.raw.hostId;
    }

    get isRoundtableEnabled(): boolean {
        return !!this.settings.isRoundtableEnabled;
    }

    get isChatCommandAudioOutputEnabled(): boolean {
        return !!this.settings.isEnabledForChatCommandAudioOutput;
    }

    get phases(): VideoAgentSessionPhase[] {
        return this.raw.phases;
    }

    get participants(): VideoAgentParticipant[] {
        return this.raw.participants;
    }

    get isPendingIntro(): boolean {
        return !!this.raw.pendingIntro;
    }

    get topics(): VideoAgentSessionTopic[] {
        return this.raw.agenda.topics;
    }

    get currentTopic(): VideoAgentSessionTopic | undefined {
        return this.raw.agenda.topics.find((topic) => topic.id === this.raw.agenda.currentTopicId);
    }

    get followingTopic(): VideoAgentSessionTopic | undefined {
        return this.getFollowingTopicFrom(1);
    }

    get roundTableTopics(): VideoAgentSessionTopic[] {
        return this.agenda.topics.filter((topic) => topic.kind === VideoAgentSessionTopicKind.Person);
    }

    get discussionTopics(): VideoAgentSessionTopic[] {
        return this.agenda.topics.filter((topic) => topic.kind === VideoAgentSessionTopicKind.Discussion);
    }

    getFollowingTopicFrom(from: number): VideoAgentSessionTopic | undefined {
        const currentTopicIndex = this.currentTopicIndex;

        if (this.isConclusionPhase) {
            return undefined;
        }

        if (this.isLobbyPhase && this.topics.length) {
            return this.topics[0];
        }

        if (currentTopicIndex === undefined) {
            return undefined;
        }

        return this.topics[currentTopicIndex + from];
    }

    get justStartedAgendaForFirstTime(): boolean {
        return this.currentTopicIndex === 0 && this.currentTopic?.startStops.length === 1;
    }

    get isOnLastTopic(): boolean {
        return !!this.currentTopic && !!this.lastTopic && this.currentTopic.id === this.lastTopic.id;
    }

    get isOnFirstTopic(): boolean {
        return !!this.currentTopic && this.currentTopicIndex === 0;
    }

    get currentTopicTimeOffset(): number {
        if (!this.currentTopic) {
            return 0;
        }

        return new FacilitatedStartStops(this.currentTopic.startStops).totalTime;
    }

    get wasAgendaStarted(): boolean {
        return this.agenda.topics.some((topic) => topic.startStops.some((startStop) => !!startStop.startedAt));
    }

    /** pass this into useTimer, and then add that value to currentTopicTimeOffset as an input to formatTime to get renderable elapsed time */
    get currentTopicStartedAt(): string {
        const now = new Date().toISOString();
        if (!this.currentTopic) {
            return now;
        }

        const startedAt = new FacilitatedStartStops(this.currentTopic.startStops).inProgress()?.startedAt;

        return startedAt || now;
    }

    get currentPhaseStartedAt(): string {
        const now = new Date().toISOString();

        const startedAt = new FacilitatedStartStops(this.currentPhase.startStops).inProgress()?.startedAt;

        return startedAt || now;
    }

    get currentPhaseTimeOffset(): number {
        return new FacilitatedStartStops(this.currentPhase.startStops).totalTime;
    }

    get currentTopicIndex(): number | undefined {
        if (!this.currentTopic) {
            return undefined;
        }

        return this.topics.findIndex((topic) => this.currentTopic?.id === topic.id);
    }

    get previousContextDemoingNames(): string[] {
        return (this.settings.demoingPreviousContextForNames || []) as string[];
    }

    /**
     * @returns `new VideoAgentSession` where the agenda is started.
     * Throws an `Error` if there are no topics to start an agenda with.
     * Does not modify the current instance's data. */
    withStartedAgenda(): VideoAgentSession {
        if (this.isAgendaPhase) {
            throw new Error('Agenda already started');
        }

        let copied: ClientVideoAgentSessionJSON = { ...this.raw };

        if (copied.agenda.topics.filter((t) => t.id !== FEEDBACK_TOPIC_ID).length === 0) {
            copied = {
                ...copied,
                agenda: {
                    ...copied.agenda,
                    topics: [
                        getNewAgentTopic('meeting discussion', { id: MEETING_DISCUSSION_TOPIC_ID }),
                        ...copied.agenda.topics,
                    ],
                },
            };
        }

        const firstAgendaItem = this.getStartedItem(copied.agenda.topics[0]);
        const updatedLobbyPhase = this.getFinishedItem(copied.phases[0]);
        const updatedAgendaPhase = this.getStartedItem(copied.phases[1]);
        const updatedPhases = [updatedLobbyPhase, updatedAgendaPhase, copied.phases[2]];

        const updatedSession: ClientVideoAgentSessionJSON = {
            ...copied,
            phases: updatedPhases,
            agenda: {
                ...copied.agenda,
                topics: copied.agenda.topics.map((topic, i) => {
                    if (i === 0) {
                        return firstAgendaItem;
                    } else {
                        return topic;
                    }
                }),
                currentTopicId: firstAgendaItem.id,
            },
        };

        return new VideoAgentSession(updatedSession);
    }

    get userFeedback(): string {
        return this.raw.userFeedback || '';
    }

    withAddedUserFeedback(feedback: string): VideoAgentSession {
        const newFeedback = this.raw.userFeedback ? this.raw.userFeedback + '\n' + feedback : feedback;
        return new VideoAgentSession({
            ...this.raw,
            userFeedback: newFeedback,
        });
    }

    withTriggersUpdated(triggers: Trigger[]): VideoAgentSession {
        return new VideoAgentSession({
            ...this.raw,
            triggers,
        });
    }

    withStartedIntro(): VideoAgentSession {
        const updatedSession: ClientVideoAgentSessionJSON = {
            ...this.raw,
            pendingIntro: false,
        };
        return new VideoAgentSession(updatedSession);
    }

    /** returns a new instance of `VideoAgentSession` where the agenda has moved forward one topic.
     * Throws an `Error` if there is no current topic or if on last topic
     * Does not modify the current instance's data. */
    withProgressedAgenda(): VideoAgentSession {
        const currentTopic = this.currentTopic;

        if (!currentTopic || this.currentTopicIndex === undefined) {
            throw new Error('attempted to progress agent agenda without starting it');
        }

        const isOnLastTopic = currentTopic.id === this.lastTopic?.id;

        let updatedNextTopic: VideoAgentSessionTopic | undefined = undefined;
        let updatedPhases = this.raw.phases;
        if (isOnLastTopic) {
            const updatedAgendaPhase = this.getFinishedItem(this.raw.phases[1]);
            const updatedConclusionPhase = this.getStartedItem(this.raw.phases[2]);
            updatedPhases = [this.raw.phases[0], updatedAgendaPhase, updatedConclusionPhase];
        } else {
            const nextTopic = this.raw.agenda.topics[this.currentTopicIndex + 1];
            updatedNextTopic = this.getStartedItem(nextTopic);
        }

        const updatedCurrentTopic = this.getFinishedItem(currentTopic);

        const updatedSession: ClientVideoAgentSessionJSON = {
            ...this.raw,
            phases: updatedPhases,
            agenda: {
                ...this.raw.agenda,
                currentTopicId: updatedNextTopic ? updatedNextTopic.id : undefined,
                topics: this.raw.agenda.topics.map((topic) => {
                    if (topic.id === currentTopic.id) {
                        return updatedCurrentTopic;
                    } else if (!!updatedNextTopic && topic.id === updatedNextTopic.id) {
                        return updatedNextTopic;
                    } else {
                        return topic;
                    }
                }),
            },
        };

        return new VideoAgentSession(updatedSession);
    }

    /** returns a new instance of `VideoAgentSession` where the agenda has moved backward one topic.
     * If the agenda was on the first item, we return to an unstarted state.
     * Throws an `Error` if there is no current topic
     * Does not modify the current instance's data. */
    withBackToPreviousTopic(): VideoAgentSession {
        const currentTopic = this.currentTopic;

        if ((!currentTopic || this.currentTopicIndex === undefined) && this.isLobbyPhase) {
            // TODO should just return `this`?
            throw new Error('attempted to reverse agent agenda without starting it');
        }

        if (this.currentPhase.id === CONCLUSION_PHASE_ID && this.lastTopic) {
            const updatedConclusionPhase = this.getFinishedItem(this.raw.phases[2]);
            const updatedAgendaPhase = this.getStartedItem(this.raw.phases[1]);
            const updatedPhases = [this.raw.phases[0], updatedAgendaPhase, updatedConclusionPhase];

            // TODO handle better
            const previousTopic = this.lastTopic;
            const updatedPrevTopic = this.getStartedItem(previousTopic);

            const updatedSession: ClientVideoAgentSessionJSON = {
                ...this.raw,
                phases: updatedPhases,
                agenda: {
                    ...this.raw.agenda,
                    currentTopicId: updatedPrevTopic.id,
                    topics: this.raw.agenda.topics.map((topic) => {
                        if (topic.id === updatedPrevTopic.id) {
                            return updatedPrevTopic;
                        } else {
                            return topic;
                        }
                    }),
                },
            };

            return new VideoAgentSession(updatedSession);
        } else if (currentTopic && this.currentTopicIndex === 0) {
            const updatedCurrentTopic = this.getFinishedItem(currentTopic);

            const updatedAgendaPhase = this.getFinishedItem(this.raw.phases[1]);
            const updatedLobbyPhase = this.getStartedItem(this.raw.phases[0]);
            const updatedPhases = [updatedLobbyPhase, updatedAgendaPhase, this.raw.phases[2]];

            const updatedSession: ClientVideoAgentSessionJSON = {
                ...this.raw,
                phases: updatedPhases,
                agenda: {
                    ...this.raw.agenda,
                    currentTopicId: undefined,
                    topics: this.raw.agenda.topics.map((topic, i) => {
                        if (i === 0) {
                            return updatedCurrentTopic;
                        } else {
                            return topic;
                        }
                    }),
                },
            };

            return new VideoAgentSession(updatedSession);
        } else {
            if (!currentTopic || this.currentTopicIndex === undefined) {
                // TODO should just return `this`?
                throw new Error('attempted to reverse agent agenda without starting it');
            }

            const updatedCurrentTopic = this.getFinishedItem(currentTopic);

            const previousTopic = this.raw.agenda.topics[this.currentTopicIndex - 1];
            const updatedPrevTopic = this.getStartedItem(previousTopic);

            const updatedSession: ClientVideoAgentSessionJSON = {
                ...this.raw,
                agenda: {
                    ...this.raw.agenda,
                    currentTopicId: updatedPrevTopic.id,
                    topics: this.raw.agenda.topics.map((topic) => {
                        if (topic.id === updatedCurrentTopic.id) {
                            return updatedCurrentTopic;
                        } else if (topic.id === updatedPrevTopic.id) {
                            return updatedPrevTopic;
                        } else {
                            return topic;
                        }
                    }),
                },
            };

            return new VideoAgentSession(updatedSession);
        }
    }

    withRemovedTopicByNumber(userFriendlyIndex: number) {
        if (userFriendlyIndex < 0) {
            throw new Error('cannot remove negative topic index');
        }

        if (this.topics.length === 1 && this.currentTopic?.id) {
            return new VideoAgentSession(this.raw);
        }

        if (userFriendlyIndex > this.topics.length) {
            throw new Error('cannot remove topic outside of existing range');
        }

        const topicToRemove = this.topics.find((topic, index) => index + 1 === userFriendlyIndex);
        if (!topicToRemove) {
            throw new Error('could not find topic by index for deletion');
        }

        // TODO: eventually allow removing current topic
        if (topicToRemove.id === this.currentTopic?.id) {
            return new VideoAgentSession(this.raw);
        }

        const updatedSession: ClientVideoAgentSessionJSON = {
            ...this.raw,
            agenda: {
                ...this.raw.agenda,
                topics: this.topics.filter((topic) => topic.id !== topicToRemove.id),
            },
        };

        return new VideoAgentSession(updatedSession);
    }

    withAddedTopics(topics: VideoAgentSessionTopic[]) {
        const updatedSession = topics.reduce((session, topic) => {
            return session.withAddedTopic(topic);
        }, new VideoAgentSession(this.raw));

        return updatedSession;
    }

    withAddedTopic(topic: VideoAgentSessionTopic) {
        let copied = { ...this.raw };

        const participantTopics = copied.agenda.topics.filter((t) => t.kind === VideoAgentSessionTopicKind.Person);
        const discussionTopics = copied.agenda.topics.filter((t) => t.kind !== VideoAgentSessionTopicKind.Person);

        let updatedTopics = [];
        if (topic.kind === VideoAgentSessionTopicKind.Person) {
            updatedTopics = [...participantTopics, topic, ...discussionTopics];
        } else {
            updatedTopics = [...participantTopics, ...discussionTopics, topic];
        }

        if (!this.feedbackTopic) {
            copied = {
                ...copied,
                agenda: {
                    ...copied.agenda,
                    topics: updatedTopics,
                },
            };
        } else {
            copied = {
                ...copied,
                agenda: {
                    ...copied.agenda,
                    topics: [...updatedTopics.filter((t) => t.id !== FEEDBACK_TOPIC_ID), this.feedbackTopic],
                },
            };
        }

        return new VideoAgentSession(copied);
    }

    withJumpedToTopicByNumber(topicNumber: number) {
        if (topicNumber > this.topics.length) {
            throw new Error('cannot jump to topic outside of existing range');
        }

        if (topicNumber < 0) {
            throw new Error('cannot jump to negative topic index');
        }

        // handle unstarted agenda once we can populate participant items from here
        const currentTopic = this.currentTopic;
        if (!currentTopic) {
            return new VideoAgentSession(this.raw);
        }

        if (typeof this.currentTopicIndex === 'number' && this.currentTopicIndex + 1 === topicNumber) {
            return new VideoAgentSession(this.raw);
        }

        const nextTopicIndex = topicNumber - 1;
        const nextTopic = this.raw.agenda.topics[nextTopicIndex];

        let updatedCurrentTopic: VideoAgentSessionTopic | null = null;
        if (currentTopic) {
            updatedCurrentTopic = this.getFinishedItem(currentTopic);
        }

        const updatedNextTopic = this.getStartedItem(nextTopic);

        const updatedSession: ClientVideoAgentSessionJSON = {
            ...this.raw,
            agenda: {
                ...this.raw.agenda,
                currentTopicId: updatedNextTopic.id,
                topics: this.raw.agenda.topics.map((topic) => {
                    if (currentTopic && updatedCurrentTopic && topic.id === currentTopic.id) {
                        return updatedCurrentTopic;
                    } else if (topic.id === updatedNextTopic.id) {
                        return updatedNextTopic;
                    } else {
                        return topic;
                    }
                }),
            },
        };

        return new VideoAgentSession(updatedSession);
    }

    withAddedContext(context: VideoAgentPreviousContext): VideoAgentSession {
        // TODO prevent duplicates per topic
        return new VideoAgentSession({
            ...this.raw,
            previousContext: {
                actionItems: [...(this.raw.previousContext?.actionItems || []), ...(context.actionItems || [])],
                blockers: [...(this.raw.previousContext?.blockers || []), ...(context.blockers || [])],
            },
        });
    }

    withToggledPaused(): VideoAgentSession {
        return new VideoAgentSession({
            ...this.raw,
            settings: {
                ...this.raw.settings,
                isPaused: !this.raw.settings.isPaused,
            },
        });
    }

    get isPaused(): boolean {
        return !!this.raw.settings.isPaused;
    }

    get previousBlockersForCurrentTopic(): MeetingBlocker[] {
        if (!this.currentTopic) {
            return [];
        }

        if (this.previousContextDemoingNames.length) {
            return (
                this.raw.previousContext?.blockers
                    ?.filter((blocker) =>
                        this.currentTopic?.title.toLocaleLowerCase().includes(blocker.user.toLocaleLowerCase())
                    )
                    ?.slice(0, 3) || []
            );
        }

        return (
            this.raw.previousContext?.blockers
                ?.filter((blocker) => blocker.user.toLocaleLowerCase() === this.currentTopic?.title.toLocaleLowerCase())
                ?.slice(0, 3) || []
        );
    }

    get previousActionItemsForCurrentTopic(): MeetingActionItem[] {
        if (!this.currentTopic) {
            return [];
        }

        if (this.previousContextDemoingNames.length) {
            return (
                this.raw.previousContext?.actionItems
                    ?.filter((actionItem) =>
                        actionItem.users.some((user) =>
                            this.currentTopic?.title.toLocaleLowerCase().includes(user.username.toLocaleLowerCase())
                        )
                    )
                    ?.slice(0, 3) || []
            );
        }

        return (
            this.raw.previousContext?.actionItems
                ?.filter((actionItem) =>
                    actionItem.users.some(
                        (user) => user.username.toLocaleLowerCase() === this.currentTopic?.title.toLocaleLowerCase()
                    )
                )
                ?.slice(0, 3) || []
        );
    }

    get feedbackTopic(): VideoAgentSessionTopic | undefined {
        return this.agenda.topics.find((topic) => topic.id === FEEDBACK_TOPIC_ID);
    }

    get isOnFeedbackTopic(): boolean {
        return !!this.currentTopic && this.currentTopic.id === FEEDBACK_TOPIC_ID;
    }

    get currentPhase(): VideoAgentSessionPhase {
        // some backward support, can remove soon
        const currentPhase = this.raw.phases.find((phase) => {
            const startStops = new FacilitatedStartStops(phase.startStops);
            return startStops.inProgress();
        });

        if (!currentPhase) {
            if (this.currentTopic) {
                return getAgendaPhase();
            } else {
                return getLobbyPhase();
            }
        } else {
            return currentPhase;
        }
    }

    get isLobbyPhase(): boolean {
        return this.currentPhase.id === LOBBY_PHASE_ID;
    }

    get isAgendaPhase(): boolean {
        return this.currentPhase.id === AGENDA_PHASE_ID;
    }

    get isConclusionPhase(): boolean {
        return this.currentPhase.id === CONCLUSION_PHASE_ID;
    }

    get lastTopic(): VideoAgentSessionTopic | undefined {
        const topicCount = this.topics.length;

        if (!topicCount) {
            return undefined;
        }

        return this.topics[this.topics.length - 1];
    }

    get firstTopic(): VideoAgentSessionTopic | undefined {
        const topicCount = this.topics.length;

        if (!topicCount) {
            return undefined;
        }

        return this.topics[0];
    }

    get lastTopicIndex(): number | undefined {
        if (!this.lastTopic) {
            return undefined;
        }

        return this.topics.findIndex((topic) => topic.id === this.lastTopic?.id);
    }

    get flatMetadata(): Record<string, Primitive> {
        return {
            botId: this.raw.botId,
            seriesId: this.raw.seriesId,
            currentTopicId: this.currentTopic?.id,
            topicsCount: this.topics.length,
            chatMessageCount: this.chatMessages.length,
            participantCount: this.participants.length,
            // TODO properly type this.raw.createdAt as its passed around
            // sessionCreatedAt: this.raw.createdAt.toISOString(),
            isRoundTableOn: this.isRoundtableEnabled,
            isAgendaStarted: !!this.currentTopic,
            isFeedbackTopicEnabled: !!this.settings.isFeedbackCollectionEnabled,
            isAudioOutputOnChatCommandsEnabled: !!this.settings.isEnabledForChatCommandAudioOutput,
        };
    }

    get analyticsPayload(): Record<string, Primitive> {
        const payload = Object.entries(this.flatMetadata).reduce<Record<string, any>>((map, [key, value]) => {
            map[`${key.charAt(0).toUpperCase()}${key.slice(1)}`] = value;
            return map;
        }, {});

        return payload;
    }

    toJSON(): ClientVideoAgentSessionJSON {
        return this.raw;
    }

    private getFinishedItem(
        item: VideoAgentSessionTopic | VideoAgentSessionPhase
    ): VideoAgentSessionTopic | VideoAgentSessionPhase {
        const updatedStartStops = new FacilitatedStartStops(item.startStops);
        updatedStartStops.finishLatest(new Date().toISOString());

        return {
            ...item,
            startStops: updatedStartStops.toJSON(),
        };
    }

    private getStartedItem(
        item: VideoAgentSessionTopic | VideoAgentSessionPhase
    ): VideoAgentSessionTopic | VideoAgentSessionPhase {
        const updatedNextItemStartStops = new FacilitatedStartStops(item.startStops);
        updatedNextItemStartStops.startNewSegment(new Date().toISOString());

        return {
            ...item,
            startStops: updatedNextItemStartStops.toJSON(),
        };
    }
}

export function getNewAgentTopic(topicName: string, overrides: Partial<VideoAgentSessionTopic> = {}) {
    const titleCasedTopicName = topicName
        .split(/\s+/)
        .map((word) => `${word.charAt(0).toLocaleUpperCase()}${word.slice(1)}`)
        .join(' ');
    return {
        id: overrides.id || v4(),
        startStops: [],
        title: titleCasedTopicName,
        kind: overrides.kind || VideoAgentSessionTopicKind.Discussion,
    };
}

export function getNewAgentChatMessage(event: RecallChatEventData): VideoAgentChatMessage {
    return {
        sentAt: event.created_at,
        text: event.text,
        sender: {
            name: event.sender.name,
        },
    };
}

/** @NOTE using a static ID for feedback topic so we can match by ID instead of title */
export const MEETING_DISCUSSION_TOPIC_ID = 'MTG_DISC_ID-6-4d22-b560-34109d5ca5ec';
export const FEEDBACK_TOPIC_ID = '3f434899-74c6-4d22-b560-34109d5ca5ec';
export function getFeedbackTopic() {
    const spinachFeedbackTopic = getNewAgentTopic('Feedback for Spinach', { id: FEEDBACK_TOPIC_ID });
    return spinachFeedbackTopic;
}

/** @NOTE using a static ID for lobby phase so we can match by ID instead of title */
export const LOBBY_PHASE_ID = 'lobby-phase-id-d22-b560-34109d5ca5e1';
export function getLobbyPhase(): VideoAgentSessionPhase {
    return getNewAgentTopic('Lobby', { id: LOBBY_PHASE_ID });
}
/** @NOTE using a static ID for agenda phase so we can match by ID instead of title */
export const AGENDA_PHASE_ID = 'agenda-phase-id-22-b560-34109d5ca5e2';
export function getAgendaPhase(): VideoAgentSessionPhase {
    return getNewAgentTopic('Agenda', { id: AGENDA_PHASE_ID });
}
/** @NOTE using a static ID for conclusion phase so we can match by ID instead of title */
export const CONCLUSION_PHASE_ID = 'conclusion-phase-id-560-34109d5ca5e3';
export function getConclusionPhase(): VideoAgentSessionPhase {
    return getNewAgentTopic('Conclusion', { id: CONCLUSION_PHASE_ID });
}

export function getInitialPhases(): VideoAgentSessionPhase[] {
    let lobbyPhase = getLobbyPhase();

    const updatedStartStops = new FacilitatedStartStops(lobbyPhase.startStops);
    updatedStartStops.startNewSegment(new Date().toISOString());

    lobbyPhase = {
        ...lobbyPhase,
        startStops: updatedStartStops.toJSON(),
    };

    return [lobbyPhase, getAgendaPhase(), getConclusionPhase()];
}

export function getNewVideoAgentSessionJSON(initialValues: NewVideoAgentSessionJSON): ClientVideoAgentSessionJSON {
    // for now assuming supplied initial topics are not roundtable topics
    const suppliedInitialTopics = initialValues.agenda?.topics || [];

    const initialRoundtableTopics = getRoundtableTopicsToAdd(
        !!initialValues.settings?.isRoundtableEnabled,
        initialValues.participants || [],
        suppliedInitialTopics
    );

    const topics = initialValues.settings?.isFeedbackCollectionEnabled
        ? [...initialRoundtableTopics, ...suppliedInitialTopics, getFeedbackTopic()]
        : [...initialRoundtableTopics, ...suppliedInitialTopics];

    return {
        id: v4(),
        settings: {},
        participants: [],
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString(),
        chatMessages: [],
        triggers: [],
        phases: getInitialPhases(),
        ...initialValues,
        agenda: {
            currentTopicId: undefined,
            topics,
        },
    };
}

export function getRoundtableTopicsToAdd(
    isRoundtableEnabled: boolean,
    participants: VideoAgentParticipant[],
    topics: VideoAgentSessionTopic[]
): VideoAgentSessionTopic[] {
    if (isRoundtableEnabled && participants.length) {
        const participantTopicsToAdd = participants
            .filter((p) => !topics.find((t) => t.id.startsWith(p.pseudoUniqueId)))
            .map((p) =>
                getNewAgentTopic(p.name, { kind: VideoAgentSessionTopicKind.Person, id: `${p.pseudoUniqueId}-${v4()}` })
            );
        return participantTopicsToAdd;
    } else {
        return [];
    }
}

/**
 *
 * @param message the full text message from chat
 * @param supportedCommandTriggers the list of supported command triggers such as "/topic add" or "-". @NOTE - be mindful of spaces, and triggers that are subsets of others. Order matters.
 */
export function getCommandContents(
    message: string,
    supportedCommandTriggers: string[]
): { commandMatched: string; remainingCommand: string } {
    const validatedTriggers = supportedCommandTriggers.filter((t) => !!`${t}`.trim());
    const trimmedMessage = message.trim();

    for (const trigger of validatedTriggers) {
        // Check if the message starts with the trigger, case-insensitively
        const triggerLength = trigger.length;
        if (trimmedMessage.substring(0, triggerLength).toLocaleLowerCase() === trigger.toLocaleLowerCase()) {
            const remainingCommand = trimmedMessage.substring(triggerLength).trim();
            return {
                commandMatched: trigger,
                remainingCommand,
            };
        }
    }

    return {
        commandMatched: '',
        remainingCommand: '',
    };
}

export const pseudoUniqueParticipantIdSuffix = '___::___';

export function getMockPreviousContext(topicName: string) {
    return {
        blockers: [
            {
                content: 'AWS Outage',
                context: '',
                user: topicName,
            },
            {
                content: 'Broken Headset',
                context: '',
                user: topicName,
            },
            {
                content: 'External Dependencies',
                context: '',
                user: topicName,
            },
        ],
        actionItems: [
            {
                context: '',
                title: 'Agent Graphics',
                users: [{ username: topicName }],
            },
            {
                context: '',
                title: 'Backlog Grooming',
                users: [{ username: topicName }],
            },
            {
                context: '',
                title: 'Plan Prioritization Session',
                users: [{ username: topicName }],
            },
        ],
    };
}
